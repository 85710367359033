import React, { useContext } from "react";
import { connect } from "react-redux";
import { History } from "react-router-dom/node_modules/history";
import { INode, ILink } from "../../shared/components/Diagram";
import { IState } from "../../reducers/interfaces";
import { IControlResult, IControlReport } from "./interfaces";
import { actionCreator } from "../duck";
import { errorType } from "../interfaces";
import { leftNavUrls } from "../../app/LeftNav.helper";
import ControlItemList from "./ControlItemList";
import { ControlContext } from "./HealthReport";
import { IUserInfo } from "../../app/interfaces";
import { filterControlResults } from "./HealthReport.helper";
import { AppContext } from "../../app/App";

export interface IControlListStateProps {
  controlReport: IControlReport;
  selectedControlNode: INode;
  selectedControlLink: ILink;
  loading: boolean;
  error: string;
  userInfo: IUserInfo;
}

export interface IControlListDispatchProps {
  loadControlResultItems: (control: IControlResult) => void;
  downloadDataToExcel: (data: any[], worksheetName: string) => void;
}

export interface IControlListProps extends IControlListStateProps, IControlListDispatchProps {
  history: History;
}

export const ControlList = (props: IControlListProps) => {
  const { viewId } = useContext(ControlContext);
  const {
    selectedControlNode,
    selectedControlLink,
    loading,
    error,
    controlReport,
    history,
    userInfo,
    downloadDataToExcel,
    loadControlResultItems,
  } = props;
  const { appState } = useContext(AppContext);
  const { featureFlags } = appState;

  let selectedItemText = "";

  if (selectedControlNode || selectedControlLink) {
    selectedItemText += " for ";
    selectedControlNode && (selectedItemText += selectedControlNode.name);
    selectedControlLink && (selectedItemText += "the selected link");
  }

  let noItemText = `No control is found${selectedItemText}.`;

  const firstItem = (controlReport?.items?.length && controlReport.items[0]) || {},
    title = selectedControlLink
      ? `${firstItem.sourceName} to ${firstItem.targetName} Controls`
      : selectedControlNode
      ? `${selectedControlNode.name || selectedControlNode.id} Related Controls`
      : "All Available Controls" + (viewId ? " Related to the Selected Control View(s)" : "");

  const onRowSelection = (control) => {
    loadControlResultItems(control);
    history.push(`${leftNavUrls.all.controls}/${viewId}/${control.id}`);
  };

  if (controlReport) {
    controlReport.items = filterControlResults(controlReport.items, userInfo);
  }

  return (
    <ControlItemList
      title={title}
      controlReport={controlReport}
      noItemText={noItemText}
      loading={loading}
      error={error}
      allowSelection
      featureFlags={featureFlags}
      onRowSelection={onRowSelection}
      downloadDataToExcel={downloadDataToExcel}
    />
  );
};

const mapStateToProps = (state: IState): IControlListStateProps => ({
  controlReport: state.modules.control_report,
  selectedControlNode: state.modules.selected_control_node,
  selectedControlLink: state.modules.selected_control_link,
  loading:
    !state.modules.loading_control_view && (state.modules.loading_controls || state.modules.loading_control_results),
  error: state.modules.errors[errorType.controlView] || state.modules.errors[errorType.controls],
  userInfo: state.app.login_user_info,
});

const mapDispatchToProps: IControlListDispatchProps = {
  loadControlResultItems: actionCreator.loadControlResultItems,
  downloadDataToExcel: actionCreator.downloadDataToExcel,
};

export default connect(mapStateToProps, mapDispatchToProps)(ControlList);
