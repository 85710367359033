import React, { useContext } from "react";
import { connect } from "react-redux";
import { IControlResult } from "./interfaces";
import { Tile } from "../../shared/components/Tile";
import { ContactLink } from "../../shared/components/ContactLink";
import { CopyToClipboard } from "../../shared/components/common/CopyToClipboard";
import { controlType, getContactMessageSubject, getOnboardingStatusText } from "./HealthReport.helper";
import { IState } from "../../reducers/interfaces";
import { getImpactScoreColor, renderImpactScore, getImpactScore } from "../common/helper";
import classNames from "./ControlHeader.module.scss";
import { Rating } from "../../shared/components/common/Rating";
import { Link } from "@fluentui/react";
import { AppContext } from "../../app/App";

export interface IControlHeaderStateProps {
  control: IControlResult;
}

export const ControlHeader = (props: IControlHeaderStateProps) => {
  const { control } = props;
  const { appState } = useContext(AppContext);
  const { featureFlags } = appState;

  if (!control) return null;

  const { resultStatus } = control;

  const isExternal = control.type === controlType.External;

  const impactScore = getImpactScore(control),
    statusColor = getImpactScoreColor(impactScore),
    headerPaneStyle = { borderLeftColor: statusColor },
    resultStatusColor = resultStatus === "pass" ? "green" : "red";

  const contactMessageSubject = getContactMessageSubject(control),
    onboardingStatusText = getOnboardingStatusText(control),
    controlDescription = control.description || "";

  const clipboardText = `<a href='${window.location.href}'>Control ${control.id}</a>: ${control.name}${
    controlDescription ? " - " : ""
  }${controlDescription}`;

  const ratingTooltipContent = featureFlags?.enableControlRating ? (
    <div className={classNames.ratingTooltipContainer}>
      <div className={classNames.ratingTooltipItem}>
        <Rating rating={4} /> <div className={classNames.ratingTooltipName}>Accuracy</div>
      </div>
      <div className={classNames.ratingTooltipItem}>
        <Rating rating={5} /> <div className={classNames.ratingTooltipName}>Timeliness</div>
      </div>
      <div className={classNames.ratingTooltipItem}>
        <Rating rating={3} /> <div className={classNames.ratingTooltipName}>Reliability</div>
      </div>
      <div className={classNames.ratingTooltipLink}>
        <Link underline>More Rating Details</Link>
      </div>
    </div>
  ) : null;

  return (
    <Tile>
      <div className={classNames.detailsHeaderPane} style={headerPaneStyle}>
        <div className={classNames.statusPane}>
          <div className={classNames.id}>Control ID: {control.id}</div>
          {!isExternal && featureFlags?.enableControlRating && (
            <div className={classNames.rating}>
              <Rating rating={4} tooltipContent={ratingTooltipContent} />
            </div>
          )}
        </div>
        <div className={classNames.headerContent}>
          <div className={classNames.title}>
            {control.name}
            {controlDescription ? ": " : ""}
            <span className={classNames.desc}>{controlDescription}</span>
            <CopyToClipboard value={clipboardText} />
          </div>
          <div className={classNames.dataFieldsPane}>
            {!isExternal && (
              <div className={classNames.dataField}>
                <span className={classNames.dataFieldName}>Status:</span>
                <span className={classNames.dataFieldValue} style={{ color: resultStatusColor }}>
                  {control.resultStatus}
                </span>
              </div>
            )}
            {impactScore >= 0 && (
              <div className={classNames.dataField} title="The control 'color' is based on the impact score.">
                <span className={classNames.dataFieldName}>Impact Score:</span>
                <span className={classNames.dataFieldValue}>{renderImpactScore(impactScore)}</span>
              </div>
            )}
            <div className={classNames.dataField}>
              <span className={classNames.dataFieldName}>Type:</span>
              <span className={classNames.dataFieldValue}>{control.type}</span>
            </div>
            {onboardingStatusText && (
              <div className={classNames.dataField}>
                <span className={classNames.dataFieldName}>Onboarding:</span>
                <span className={classNames.dataFieldValue}>{onboardingStatusText}</span>
              </div>
            )}
            {control.sourceName && (
              <div className={classNames.dataField}>
                <span className={classNames.dataFieldName}>Source:</span>
                <span className={classNames.dataFieldValue}>{control.sourceName}</span>
              </div>
            )}
            {control.targetName && (
              <div className={classNames.dataField}>
                <span className={classNames.dataFieldName}>Target:</span>
                <span className={classNames.dataFieldValue}>{control.targetName}</span>
              </div>
            )}
            {control.owner && (
              <div className={classNames.dataField}>
                <span className={classNames.dataFieldName}>Owner:</span>
                <span className={classNames.dataFieldValue}>
                  <ContactLink alias={control.owner} messageSubject={contactMessageSubject} />
                </span>
              </div>
            )}
            {control.owningTeam && (
              <div className={classNames.dataField}>
                <span className={classNames.dataFieldName}>Team:</span>
                <span className={classNames.dataFieldValue}>
                  <ContactLink alias={control.owningTeam} messageSubject={contactMessageSubject} />
                </span>
              </div>
            )}
            {control.editors && (
              <div className={classNames.dataField}>
                <span className={classNames.dataFieldName}>Editors:</span>
                <span className={classNames.dataFieldValue}>
                  <ContactLink alias={control.editors} messageSubject={contactMessageSubject} />
                </span>
              </div>
            )}
          </div>
        </div>
      </div>
    </Tile>
  );
};

const mapStateToProps = (state: IState): IControlHeaderStateProps => ({
  control: state.modules.selected_control,
});

export default connect(mapStateToProps)(ControlHeader);
