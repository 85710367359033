import React, { useContext, useState } from "react";
import { connect } from "react-redux";
import ControlHeader from "./ControlHeader";
import ControlSummary from "./controlSummary/ControlSummary";
import LineItemList from "./LineItemList";
import CommentModal from "../common/CommentModal";
import LinkedItemModal from "../common/LinkedItemModal";
import { IState } from "../../reducers/interfaces";
import ControlComments from "./ControlComments";
import ControlLinkedItems from "./ControlLinkedItems";
import { actionCreator } from "../duck";
import { IComment, ILinkedItem } from "../common/interfaces";
import { errorType } from "../interfaces";
import classNames from "./ControlDetails.module.scss";
import { IControlResult } from "./interfaces";
import { CollapseToggleIcon, TogglePosition } from "../common/CollapseToggleIcon";
import { MSSalesAccessDeniedMessage } from "../common/MSSalesAccessDeniedMessage";
import ControlErrors from "./ControlErrors";
import { convertUtcToLocalTime } from "../../shared/utilities/miscHelper";
import { controlType } from "./HealthReport.helper";
import { Tile } from "../../shared/components/Tile";
import { Pivot, PivotItem } from "@fluentui/react";
import ControlRating from "./ControlRating";
import { AppContext } from "../../app/App";

const sideContentWidth = 260;

export interface IControlDetailsStateProps {
  isSmallScreen: boolean;
  commentCount: number;
  linkedItemCount: number;
  editingComment: IComment;
  savingComment: boolean;
  savingCommentError: string;
  editingLinkedItem: ILinkedItem;
  savingLinkedItem: boolean;
  savingLinkedItemError: string;
  selectedControl: IControlResult;
  loading: boolean;
}

export interface IControlDetailsDispatchProps {
  updateControlComment: (comment: IComment) => void;
  cancelControlCommentEdit: () => void;
  updateControlLinkedItem: (linkedItem: ILinkedItem) => void;
  cancelControlLinkedItemEdit: () => void;
}

export const ControlDetails = (props: IControlDetailsStateProps & IControlDetailsDispatchProps) => {
  const [sideContentCollapsed, setSideContentCollapsed] = useState<boolean>(false);
  const {
    commentCount,
    linkedItemCount,
    isSmallScreen,
    editingComment,
    savingComment,
    savingCommentError,
    editingLinkedItem,
    savingLinkedItem,
    savingLinkedItemError,
    loading,
    updateControlComment,
    cancelControlCommentEdit,
    updateControlLinkedItem,
    cancelControlLinkedItemEdit,
    selectedControl,
  } = props;
  const { appState } = useContext(AppContext);
  const { featureFlags } = appState;

  const isUserRestricted = selectedControl?.isRestricted && !selectedControl?.doesUserHasFullMSSalesAccess;

  const hasSideContent = commentCount > 0 || linkedItemCount > 0,
    showSideContent = hasSideContent && !sideContentCollapsed,
    mainContentStyle = { right: showSideContent ? sideContentWidth : 0 },
    sideContentStyle = {
      width: isSmallScreen ? "100%" : showSideContent ? sideContentWidth : 0,
      opacity: showSideContent ? 1 : 0,
    };

  const isExternal = selectedControl?.type === controlType.External;

  return (
    <div className={classNames.root}>
      <div className={classNames.mainContent} style={mainContentStyle}>
        <ControlHeader />
        {isUserRestricted ? (
          <MSSalesAccessDeniedMessage objectTypeName="control" />
        ) : (
          <>
            {isExternal ? (
              <Tile>
                <div className={classNames.externalLinkPane}>
                  External Control Link: &nbsp;
                  <a href={selectedControl?.externalUrl} target="_blank" rel="noreferrer">
                    {selectedControl?.externalUrl}
                  </a>
                </div>
              </Tile>
            ) : (
              <Pivot linkSize="large">
                <PivotItem key="results" headerText="Results">
                  {/* TODO: Remove this component ControlAction completely if this feature isn't going to be used in the future. Meanwhile, will keep it hidden from view.*/}
                  {/*<ControlAction />*/}
                  <ControlSummary />
                  <LineItemList />
                </PivotItem>
                {featureFlags?.enableControlRating && (
                  <PivotItem key="rating" headerText="Rating">
                    <ControlRating />
                  </PivotItem>
                )}
                <PivotItem key="troubleshoot" headerText="Troubleshoot">
                  <ControlErrors controlId={selectedControl?.id} />
                </PivotItem>
              </Pivot>
            )}
            {!loading && selectedControl && (
              <div className={classNames.footerInfo}>
                <div className={classNames.footerInfoItem}>Owner: {selectedControl.owner}</div>
                <div className={classNames.footerInfoItem}>
                  Last Modified: {new Date(Number(selectedControl["_ts"]) * 1000).toLocaleString()}
                  {selectedControl["_modifiedBy"] && <span> by {selectedControl["_modifiedBy"]}</span>}
                </div>
                {selectedControl["publishedOn"] && (
                  <div className={classNames.footerInfoItem}>
                    Last Published: {convertUtcToLocalTime(selectedControl["publishedOn"])} by{" "}
                    {selectedControl["publishedBy"]}
                  </div>
                )}
              </div>
            )}
          </>
        )}
      </div>
      {hasSideContent && (
        <CollapseToggleIcon
          isCollapsed={sideContentCollapsed}
          togglePosition={TogglePosition.right}
          onClick={() => setSideContentCollapsed(!sideContentCollapsed)}
        />
      )}
      <div className={classNames.sideContent} style={sideContentStyle}>
        <ControlComments />
        <ControlLinkedItems />
      </div>
      {editingComment && (
        <CommentModal
          comment={editingComment}
          onCommit={updateControlComment}
          onCancel={cancelControlCommentEdit}
          saving={savingComment}
          error={savingCommentError}
        />
      )}
      {editingLinkedItem && (
        <LinkedItemModal
          linkedItem={editingLinkedItem}
          onCommit={updateControlLinkedItem}
          onCancel={cancelControlLinkedItemEdit}
          saving={savingLinkedItem}
          error={savingLinkedItemError}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state: IState): IControlDetailsStateProps => {
  let control = state.modules.selected_control;
  return {
    isSmallScreen: state.app.is_small_screen,
    commentCount: control && control.comments && control.comments.length,
    linkedItemCount: control && control.linkedItems && control.linkedItems.length,
    editingComment: state.modules.editing_control_comment,
    savingComment: state.modules.saving_control_comment,
    savingCommentError: state.modules.errors[errorType.controlComment],
    editingLinkedItem: state.modules.editing_control_linked_item,
    savingLinkedItem: state.modules.saving_control_linked_item,
    savingLinkedItemError: state.modules.errors[errorType.controlLinkedItem],
    selectedControl: control,
    loading: state.modules.loading_control_results || state.modules.loading_control_result_items,
  };
};

const mapDispatchToProps = {
  updateControlComment: actionCreator.updateControlComment,
  cancelControlCommentEdit: actionCreator.cancelControlCommentEdit,
  updateControlLinkedItem: actionCreator.updateControlLinkedItem,
  cancelControlLinkedItemEdit: actionCreator.cancelControlLinkedItemEdit,
};

export default connect(mapStateToProps, mapDispatchToProps)(ControlDetails);
