import { actionCreator } from "./duck";
import { actionCreator as appActionCreator } from "../app/duck";
import * as radarApi from "../api/radarApi";
import { IIncident, IIncidentView } from "./incidents/interfaces";
import { IControlResult, IControlResultStatus, IControl, IControlView, IEntity } from "./controls/interfaces";
import { getEntityIds } from "./controls/HealthReport.helper";
import { Dispatch } from "redux";
import { defaultControlHistoryTimeSelectorValue } from "./controls/controlSummary/ControlResultSummary";
import { convertToTimeSpanInDays } from "../shared/components/TimeSelector";
import {
  IComment,
  ILinkedItem,
  IReportGroup,
  IFilterItem,
  IAppSetting,
  IEventSubscription,
  IDevOpsView,
  IChecklist,
  IEvent,
  IFinancialEntitySearch,
  IAnomalyTest,
  ICollectorService,
  IEntityView,
  IEntityTraceView,
  ITeam,
  ReportType,
  IConfigItem,
} from "./common/interfaces";
import { appInsights } from "../app/telemetryHelper";
import { leftNavUrls } from "../app/LeftNav.helper";
import { featureRequestsViewId, getDevOpsFilterItems } from "./works/helper";
import { getAnchorEntityTypes, SearchItemKey, getDisplayColumns, getIndexedAttributes } from "./graph/helper";
import { IAppModuleState } from "./interfaces";
import { getDateTimeISOString } from "../shared/utilities/miscHelper";
import { convertFromConfigToField, IConfigDefs } from "./management/configDefs/ConfigDefsEdit.helper";
import { IEnvironment } from "../app/interfaces";

export const isStaging = (getState?: Function, configItemType?: radarApi.ConfigItemType): boolean =>
  configItemType && radarApi.LiveOnlyConfigItemTypes.indexOf(configItemType) >= 0
    ? false
    : getState && getState().app.is_staging;

export const loadIncidents =
  (incidentViewId: string, incidentId: string = null, searchText: string = null, refreshData: boolean = false) =>
  (dispatch: Dispatch, getState?: Function) => {
    appInsights.startTrackEvent("LoadIncidents");
    dispatch(actionCreator.requestIncidents());

    radarApi
      .fetchIncidents(incidentViewId, refreshData, isStaging(getState))
      .then((incidents) => {
        if (incidentId) {
          let incident = incidents && (incidents as IIncident[]).find((i) => i.incidentId.toString() === incidentId);
          if (incident) {
            dispatch(actionCreator.receivedIncidents(incidents));
            loadIncidentDetails(incident)(dispatch, getState);
          } else {
            dispatch(actionCreator.receivedIncidents(null));
            loadIncident(incidentId)(dispatch, getState);
          }
        } else {
          dispatch(actionCreator.receivedIncidents(incidents));
          searchText && dispatch(actionCreator.changeIncidentSearchText(searchText));
          loadIncidentTiles(incidentViewId, refreshData)(dispatch, getState);
        }

        let incidentViews = getState && getState().modules.incident_views,
          incidentView = incidentViews && incidentViews.find((view) => view.id === incidentViewId),
          incidentViewName = incidentView && incidentView.name;

        appInsights.stopTrackEvent("LoadIncidents", {
          incidentViewId,
          incidentViewName,
          refreshData: refreshData.toString(),
        });
      })
      .catch((error) => dispatch(actionCreator.requestIncidentsFailed(error)));
  };

export const loadIncident =
  (incidentId: string, withDetails: boolean = true, refreshData: boolean = false) =>
  (dispatch: Dispatch, getState?: Function) => {
    appInsights.startTrackEvent("LoadIncident");
    dispatch(actionCreator.loadIncidentStarted(incidentId));

    if (incidentId) {
      radarApi
        .fetchIncident(incidentId, refreshData, isStaging(getState))
        .then((incident: IIncident) => {
          dispatch(actionCreator.loadIncidentCompleted(incident));

          incident && withDetails && loadIncidentDetailsById(incident.incidentId, refreshData)(dispatch, getState);

          appInsights.stopTrackEvent("LoadIncident", {
            incidentId,
            refreshData: refreshData.toString(),
          });
        })
        .catch((error) => dispatch(actionCreator.loadIncidentFailed(error)));
    } else {
      dispatch(actionCreator.loadIncidentFailed("No incident id defined."));
    }
  };

export const loadIncidentViewsOnly =
  (refreshData: boolean = false) =>
  (dispatch: Dispatch, getState?: Function) =>
    loadIncidentViews(null, null, null, refreshData)(dispatch, getState);

export const loadIncidentViews =
  (incidentId: string = null, incidentViewId: string = null, searchText: string = null, refreshData: boolean = false) =>
  (dispatch: Dispatch, getState?: Function) => {
    appInsights.startTrackEvent("LoadIncidentViews");
    dispatch(actionCreator.requestIncidentViews());

    radarApi
      .fetchIncidentViews(refreshData, isStaging(getState))
      .then((incidentViews: any) => {
        let defaultViewId = incidentViewId || incidentViews[0].id;
        dispatch(actionCreator.receivedIncidentViews(incidentViews, defaultViewId));

        if (incidentViews && incidentViews.length) {
          loadIncidents(defaultViewId, incidentId, searchText, refreshData)(dispatch, getState);
        }

        appInsights.stopTrackEvent("LoadIncidentViews", {
          incidentId,
          incidentViewId,
          refreshData: refreshData.toString(),
        });
      })
      .catch((error) => dispatch(actionCreator.requestIncidentViewsFailed(error)));
  };

export const loadIncidentDetails =
  (incident: IIncident, refreshData: boolean = false) =>
  (dispatch: Dispatch, getState?: Function) => {
    let id = incident?.incidentId;

    if (!id) {
      return;
    }

    appInsights.startTrackEvent("LoadIncidentDetails");

    loadIncidentDetailsById(id, refreshData)(dispatch, getState);
    dispatch(actionCreator.selectIncident(incident));
  };

export const loadIncidentDetailsById =
  (incidentId: string, refreshData: boolean = false) =>
  (dispatch: Dispatch, getState?: Function) => {
    if (!incidentId) {
      return;
    }

    appInsights.startTrackEvent("loadIncidentDetailsById");

    dispatch(actionCreator.requestIncidentDetails(incidentId));

    radarApi
      .fetchIncidentDetails(incidentId, refreshData, isStaging(getState))
      .then((result) => {
        dispatch(actionCreator.receivedIncidentDetails(incidentId, result));
        appInsights.stopTrackEvent("loadIncidentDetailsById", { incidentId, refreshData: refreshData.toString() });
      })
      .catch((error) => dispatch(actionCreator.requestIncidentDetailsFailed(incidentId, error)));
  };

export const loadIncidentTiles =
  (incidentViewId: string, refreshData: boolean = false) =>
  (dispatch: Dispatch, getState?: Function) => {
    appInsights.startTrackEvent("LoadIncidentTiles");
    dispatch(actionCreator.requestIncidentTiles(incidentViewId));

    radarApi
      .fetchIncidentTiles(incidentViewId, refreshData, isStaging(getState))
      .then((tileResults: any) => {
        dispatch(actionCreator.receivedIncidentTiles(incidentViewId, tileResults));
        appInsights.stopTrackEvent("LoadIncidentTiles", { incidentViewId, refreshData: refreshData.toString() });
      })
      .catch((error) => dispatch(actionCreator.requestIncidentTilesFailed(error)));
  };

export const loadIncidentOpenAiSummaries =
  (filteredIncidentIds: string[], refreshData: boolean = false) =>
  (dispatch: Dispatch, getState?: Function) => {
    let incidents = getState().modules.filtered_incidents;

    filteredIncidentIds?.length &&
      filteredIncidentIds
        .sort((a, b) => (a < b ? 1 : a > b ? -1 : 0))
        .forEach((incidentId, index) => {
          let targetIncident = incidents?.find((incident) => Number(incident.incidentId) === Number(incidentId));

          if (!targetIncident || !targetIncident["openAiSummary"]) {
            setTimeout(() => loadIncidentOpenAiSummary(incidentId, refreshData)(dispatch, getState), index * 500);
          }
        });
  };

export const loadIncidentOpenAiSummary =
  (incidentId: string, refreshData: boolean = false) =>
  (dispatch: Dispatch, getState?: Function) => {
    appInsights.startTrackEvent("LoadIncidentOpenAiSummary");
    dispatch(actionCreator.loadIncidentOpenAiSummaryStarted(incidentId));

    radarApi
      .fetchIncidentOpenAiSummary(incidentId, refreshData, isStaging(getState))
      .then((result: any) => {
        let summaryText = result?.responseText || result?.error;

        dispatch(actionCreator.loadIncidentOpenAiSummaryCompleted(incidentId, summaryText));

        appInsights.stopTrackEvent("LoadIncidentOpenAiSummary", {
          incidentId: incidentId,
          refreshData: refreshData.toString(),
        });
      })
      .catch((error) => dispatch(actionCreator.loadIncidentOpenAiSummaryFailed(incidentId, error)));
  };

export const loadIncidentView = (incidentViewId: string) => (dispatch: Dispatch, getState?: Function) => {
  appInsights.startTrackEvent("LoadIncidentView");
  dispatch(actionCreator.loadConfigItemStarted(incidentViewId));

  radarApi
    .fetchIncidentView(incidentViewId, true, isStaging(getState))
    .then((incidentView: any) => {
      dispatch(actionCreator.loadConfigItemCompleted(incidentView));
      appInsights.stopTrackEvent("LoadIncidentView", {
        incidentViewId,
        incidentViewName: incidentView && incidentView.name,
      });
    })
    .catch((error) => dispatch(actionCreator.loadConfigItemFailed(error)));
};

export const updateIncidentView = (incidentView: IIncidentView) => (dispatch: Dispatch, getState?: Function) => {
  appInsights.startTrackEvent("UpdateIncidentView");
  dispatch(actionCreator.updateConfigItemStarted());

  radarApi
    .updateIncidentView(incidentView, isStaging(getState))
    .then((newIncidentView: any) => {
      dispatch(actionCreator.updateConfigItemCompleted(radarApi.ConfigItemType.IncidentViews, newIncidentView));
      loadIncidentViews(null, null, null, true)(dispatch, getState);

      if (incidentView.id === "new") {
        window.location.href = `/#${leftNavUrls.management.incidentView}/${newIncidentView.id}`;
      }
      appInsights.stopTrackEvent("UpdateIncidentView", {
        incidentViewId: incidentView.id,
        newIncidentViewId: newIncidentView.id,
      });
    })
    .catch((error) => dispatch(actionCreator.updateConfigItemFailed(error)));
};

export const deleteIncidentView = (incidentViewId: string) => (dispatch: Dispatch, getState?: Function) => {
  appInsights.startTrackEvent("DeleteIncidentView");
  dispatch(actionCreator.deleteConfigItemStarted());
  radarApi
    .deleteIncidentView(incidentViewId, isStaging(getState))
    .then(() => {
      loadIncidentViews(null, null, null, true)(dispatch, getState);
      dispatch(actionCreator.deleteConfigItemCompleted());
      appInsights.stopTrackEvent("DeleteIncidentView", { incidentViewId });
    })
    .catch((error) => dispatch(actionCreator.deleteConfigItemFailed(error)));
};

export const downloadIncidentsDataToExcel =
  (data: any[], incidentViewId: string) => (dispatch: Dispatch, getState?: Function) => {
    appInsights.startTrackEvent("DownloadIncidentsDataToExcel");
    dispatch(actionCreator.downloadIncidentsDataToExcelStarted());
    radarApi
      .downloadIncidentsDataToExcel(data, isStaging(getState))
      .then(() => {
        dispatch(actionCreator.downloadIncidentsDataToExcelCompleted());
        appInsights.stopTrackEvent("DownloadIncidentsDataToExcel", { incidentViewId });
      })
      .catch((error) => dispatch(actionCreator.downloadIncidentsDataToExcelFailed(error)));
  };

export const downloadDataToExcel =
  (data: any[], worksheetName: string) => (dispatch: Dispatch, getState?: Function) => {
    appInsights.startTrackEvent("DownloadDataToExcel");
    radarApi.downloadDataToExcel(data, worksheetName, isStaging(getState)).then(() => {
      appInsights.stopTrackEvent("DownloadDataToExcel", { worksheetName });
    });
  };

export const createIncident = (incidentData: any) => (dispatch: Dispatch, getState?: Function) => {
  appInsights.startTrackEvent("CreateIncident");
  //dispatch(actionCreator.createIncidentStarted());
  radarApi
    .createIncident(incidentData, isStaging(getState))
    .then((result) => {
      //dispatch(actionCreator.createIncidentCompleted());
      appInsights.stopTrackEvent("CreateIncident", { incidentData });
    })
    .catch((error) => {}); //dispatch(actionCreator.createIncidentFailed(error)));
};

export const loadControlResults =
  (control: IControlResult = null, refreshData: boolean = false, postControlResultsCallback: () => void = null) =>
  (dispatch: Dispatch, getState?: Function) => {
    appInsights.startTrackEvent("LoadControlResults");
    dispatch(actionCreator.requestControlResults());

    radarApi
      .fetchControlResults(refreshData, isStaging(getState))
      .then((controlResults: any) => {
        dispatch(actionCreator.receivedControlResults(controlResults, getState().app.login_user_info));

        if (control) {
          var controlResult = controlResults && controlResults.find((result) => result.controlId === control.id),
            controlResultId = controlResult && controlResult.controlResultId;

          loadControlResultItems({ ...control, controlResultId }, controlResultId, refreshData)(dispatch, getState);
        }

        postControlResultsCallback && postControlResultsCallback();

        appInsights.stopTrackEvent("LoadControlResults", {
          controlId: control?.id,
          refreshData: refreshData.toString(),
        });
      })
      .catch((error) => dispatch(actionCreator.requestControlResultsFailed(error)));
  };

export const loadControlResultItems =
  (control: IControlResult, controlResultId: string = null, refreshData: boolean = false) =>
  (dispatch: Dispatch, getState?: Function) => {
    if (!control || !control.id) {
      return;
    }

    const controlId = control.id;

    appInsights.startTrackEvent("LoadControlResultItems");
    dispatch(actionCreator.requestControlResultItems(controlId));
    dispatch(actionCreator.selectControl(controlId));

    const loadAllItems = control.loadAllItems !== undefined ? control.loadAllItems : control.type === "Outlier";

    const resultId = controlResultId || control.controlResultId;
    resultId && loadControlResultDetails(controlId, resultId, refreshData)(dispatch, getState);

    radarApi
      .fetchControlResultItems(controlId, controlResultId, loadAllItems, refreshData, isStaging(getState))
      .then((result: any) => {
        dispatch(actionCreator.receivedControlResultItems(result?.lineItems, result?.totalCount));

        let tiles = control.tiles || control.outputConfig.tiles;
        tiles && tiles.length && loadControlTiles(controlId, "", refreshData)(dispatch, getState);

        const timeAgo = convertToTimeSpanInDays(defaultControlHistoryTimeSelectorValue);
        (refreshData || !control.resultHistory) &&
          loadControlResultHistory(controlId, timeAgo, refreshData)(dispatch, getState);

        appInsights.stopTrackEvent("LoadControlResultItems", {
          controlId,
          controlResultId,
          refreshData: refreshData.toString(),
        });
      })
      .catch((error) => dispatch(actionCreator.requestControlResultItemsFailed(error)));
  };

export const loadControlViews =
  (
    controlViewIds: string,
    refreshData: boolean = false,
    hasControls: boolean = false,
    controlId: string = null,
    postControlResultsCallback: () => void = null
  ) =>
  (dispatch: Dispatch, getState?: Function) => {
    if (!controlViewIds) {
      return;
    }

    appInsights.startTrackEvent("LoadControlViews");
    dispatch(actionCreator.selectControlFilter(controlViewIds, getState().app.login_user_info));
    dispatch(actionCreator.requestControlViews(controlViewIds));

    radarApi
      .fetchControlViews(controlViewIds, refreshData, isStaging(getState))
      .then((data: any) => {
        const controlViews = Array.isArray(data) ? data : [data];

        dispatch(actionCreator.receivedControlViews(controlViews));
        hasControls
          ? loadControlResults(null, refreshData, postControlResultsCallback)(dispatch, getState)
          : loadControls(controlId, refreshData, postControlResultsCallback)(dispatch, getState);

        const isGlobalEnvironment = !getState().login_user_info?.isNonGlobalEnvironment;

        if (isGlobalEnvironment) {
          const entityIds = getEntityIds(controlViews);
          loadEntities(entityIds, refreshData)(dispatch, getState);
        }

        appInsights.stopTrackEvent("LoadControlViews", {
          controlViewIds,
          controlId,
          refreshData: refreshData.toString(),
        });
      })
      .catch((error) => dispatch(actionCreator.requestControlViewsFailed(error)));
  };

export const loadControlsOnly =
  (refreshData: boolean = false) =>
  (dispatch: Dispatch, getState?: Function) =>
    loadControls(null, refreshData)(dispatch, getState);

export const loadControls =
  (controlId?: string, refreshData: boolean = false, postControlResultsCallback: () => void = null) =>
  (dispatch: Dispatch, getState?: Function) => {
    appInsights.startTrackEvent("LoadControls");
    dispatch(actionCreator.requestControls());

    radarApi
      .fetchControls(refreshData, isStaging(getState))
      .then((controls: any) => {
        dispatch(actionCreator.receivedControls(controls));

        let control = controlId && controls.find((control) => control.id === controlId);

        if (controlId && !control) {
          dispatch(actionCreator.requestControlsFailed("Cannot find control data with control ID " + controlId));
          return;
        }

        control = control && {
          ...control,
          ...control.outputConfig,
        };

        loadControlResults(control, refreshData, postControlResultsCallback)(dispatch, getState);

        appInsights.stopTrackEvent("LoadControls", { controlId, refreshData: refreshData.toString() });
      })
      .catch((error) => dispatch(actionCreator.requestControlsFailed(error)));
  };

export const changeEntityView = (entityViewId: string) => (dispatch: Dispatch, getState?: Function) => {
  appInsights.startTrackEvent("ChangeEntityView");
  dispatch(actionCreator.selectEntityView(entityViewId));

  loadEntityMetrics()(dispatch, getState);
};

export const loadEntities =
  (entityIds: string, refreshData: boolean = false) =>
  (dispatch: Dispatch, getState?: Function) => {
    appInsights.startTrackEvent("LoadEntities");
    dispatch(actionCreator.requestEntities(entityIds));

    radarApi
      .fetchEntities(refreshData, isStaging(getState))
      .then((entities: any) => {
        dispatch(actionCreator.receivedEntities(entities));
        appInsights.stopTrackEvent("LoadEntities", { entityIds, refreshData: refreshData.toString() });
      })
      .catch((error) => dispatch(actionCreator.requestEntitiesFailed(error)));
  };

export const loadEntityTests =
  (entityType: string, entityId: string, entityVersion: string, refreshData: boolean = false) =>
  (dispatch: Dispatch, getState?: Function) => {
    appInsights.startTrackEvent("LoadEntityTests");
    dispatch(actionCreator.loadEntityTestsStarted());

    radarApi
      .loadEntityTests(entityType, entityId, entityVersion, refreshData, isStaging(getState))
      .then((tests: any) => {
        dispatch(actionCreator.loadEntityTestsCompleted(tests));

        loadEntityTraceMetrics(entityType, entityId, entityVersion, refreshData)(dispatch, getState);

        loadEntityRelationships(entityType, entityId, entityVersion, refreshData)(dispatch, getState);

        appInsights.stopTrackEvent("LoadEntityTests", {
          entityType,
          entityId,
          entityVersion,
          refreshData: refreshData.toString(),
        });
      })
      .catch((error) => dispatch(actionCreator.loadEntityTestsFailed(error)));
  };

export const loadEntityMetrics =
  (startTime: Date = null, endTime: Date = null, refreshData: boolean = false) =>
  (dispatch: Dispatch, getState?: Function) => {
    var state = getState().modules,
      selectedEntityView = state.entity_views?.find((view) => view.id === state.selected_entity_view_id),
      entityTypes = state.entity_types,
      entityViewLevel = selectedEntityView?.viewLevel,
      statsId = selectedEntityView?.metricSnapshotStatsId,
      isEntitySearch = state.search_item_key === SearchItemKey.entitySearch,
      searchFilters = isEntitySearch ? state.entity_search_filters : state.metric_search_filters;

    if (statsId) {
      searchFilters = state.search_view_filters;
    } else {
      searchFilters = searchFilters?.concat(state.search_view_filters);
    }

    startTime = startTime || (isEntitySearch ? null : state.search_entity_start_time);
    endTime = endTime || (isEntitySearch ? null : state.search_entity_end_time);

    appInsights.startTrackEvent("LoadEntityMetrics");
    dispatch(actionCreator.loadEntityMetricsStarted(startTime, endTime));

    const anchorEntityTypes = isEntitySearch
      ? [state.search_entity_type]
      : getAnchorEntityTypes(state.entity_views, state.selected_entity_view_id, state.entity_types);

    const anchorDateAttribute = selectedEntityView?.anchorDateAttribute,
      maxVersionOnly = selectedEntityView?.maxVersionOnly,
      monthValueDiff = selectedEntityView?.monthValueDiff,
      startTimeText = getDateTimeISOString(startTime, monthValueDiff),
      endTimeText = getDateTimeISOString(endTime, monthValueDiff, true),
      indexedAttributes = getIndexedAttributes(anchorEntityTypes, state.service_content_configs),
      currentTime = new Date();

    radarApi
      .loadEntityMetrics(
        anchorEntityTypes,
        startTimeText,
        endTimeText,
        searchFilters,
        maxVersionOnly,
        anchorDateAttribute,
        indexedAttributes,
        statsId,
        entityViewLevel,
        entityTypes,
        refreshData,
        isStaging(getState)
      )
      .then((result: any) => {
        var { metrics, executionTimestamp } = result;

        var queryTime = new Date().getTime() - currentTime.getTime();

        dispatch(actionCreator.loadEntityMetricsCompleted(metrics, executionTimestamp, queryTime));

        !isEntitySearch && loadEntityTiles(refreshData)(dispatch, getState);

        appInsights.stopTrackEvent("LoadEntityMetrics", {
          startTimeText,
          endTimeText,
          maxVersionOnly,
          anchorDateAttribute,
          refreshData: refreshData.toString(),
        });
      })
      .catch((error) => dispatch(actionCreator.loadEntityMetricsFailed(error)));
  };

export const loadEntityTraceMetrics =
  (entityType: string, entityId: string, entityVersion: string, refreshData: boolean = false) =>
  (dispatch: Dispatch, getState?: Function) => {
    appInsights.startTrackEvent("LoadEntityTraceMetrics");
    dispatch(actionCreator.loadEntityTraceMetricsStarted());

    radarApi
      .loadEntityTraceMetrics(entityType, entityId, entityVersion, refreshData, isStaging(getState))
      .then((metrics: any) => {
        dispatch(actionCreator.loadEntityTraceMetricsCompleted(metrics));
        appInsights.stopTrackEvent("LoadEntityTraceMetrics", {
          entityType,
          entityId,
          entityVersion,
          refreshData: refreshData.toString(),
        });
      })
      .catch((error) => dispatch(actionCreator.loadEntityTraceMetricsFailed(error)));
  };

export const loadEntityTiles =
  (refreshData: boolean = false) =>
  (dispatch: Dispatch, getState?: Function) => {
    appInsights.startTrackEvent("LoadEntityTiles");
    dispatch(actionCreator.loadEntityTilesStarted());

    var state = getState().modules,
      anchorEntityTypes = getAnchorEntityTypes(state.entity_views, state.selected_entity_view_id, state.entity_types),
      entityTypes = state.entity_types,
      startTime = state.search_entity_start_time,
      endTime = state.search_entity_end_time,
      searchFilters = state.metric_search_filters,
      selectedEntityView = state.entity_views?.find((view) => view.id === state.selected_entity_view_id),
      statsId = selectedEntityView?.metricSnapshotStatsId,
      anchorDateAttribute = selectedEntityView?.anchorDateAttribute,
      maxVersionOnly = selectedEntityView?.maxVersionOnly,
      monthValueDiff = selectedEntityView?.monthValueDiff,
      startTimeText = getDateTimeISOString(startTime, monthValueDiff),
      endTimeText = getDateTimeISOString(endTime, monthValueDiff, true),
      indexedAttributes = getIndexedAttributes(anchorEntityTypes, state.service_content_configs);

    if (statsId) {
      searchFilters = state.search_view_filters;
    } else {
      searchFilters = searchFilters?.concat(state.search_view_filters);
    }

    radarApi
      .loadEntityTiles(
        anchorEntityTypes,
        entityTypes,
        startTimeText,
        endTimeText,
        searchFilters,
        maxVersionOnly,
        anchorDateAttribute,
        indexedAttributes,
        statsId,
        refreshData,
        isStaging(getState)
      )
      .then((entityTiles: any) => {
        dispatch(actionCreator.loadEntityTilesCompleted(entityTiles));
        appInsights.stopTrackEvent("LoadEntityTiles", {
          anchorEntityTypes: anchorEntityTypes.join(","),
          entityTypes: entityTypes.join(","),
          startTimeText,
          endTimeText,
          anchorDateAttribute,
          statsId,
          refreshData: refreshData.toString(),
        });
      })
      .catch((error) => dispatch(actionCreator.loadEntityTilesFailed(error)));
  };

export const loadFinancialEntities =
  (
    startTime: Date = null,
    endTime: Date = null,
    entityType: string = null,
    metricId: string = null,
    refreshData: boolean = false
  ) =>
  (dispatch: Dispatch, getState?: Function) => {
    appInsights.startTrackEvent("LoadFinancialEntities");
    dispatch(actionCreator.loadFinancialEntitiesStarted(startTime, endTime));

    var state: IAppModuleState = getState().modules,
      isEntitySearch = state.search_item_key === SearchItemKey.entitySearch,
      anchorEntityTypes = getAnchorEntityTypes(state.entity_views, state.selected_entity_view_id, state.entity_types),
      searchEntityTypes = state.search_entity_type ? [state.search_entity_type] : null,
      entityTypes = isEntitySearch ? searchEntityTypes : anchorEntityTypes,
      searchFilters = isEntitySearch ? state.entity_search_filters : state.metric_search_filters,
      selectedEntityView = state.entity_views?.find((view) => view.id === state.selected_entity_view_id),
      statsId = selectedEntityView?.metricSnapshotStatsId,
      anchorDateAttribute = selectedEntityView?.anchorDateAttribute,
      maxVersionOnly = selectedEntityView?.maxVersionOnly,
      monthValueDiff = selectedEntityView?.monthValueDiff,
      startTimeText = getDateTimeISOString(startTime, monthValueDiff),
      endTimeText = getDateTimeISOString(endTime, monthValueDiff, true),
      displayColumns = getDisplayColumns(entityType || state.search_entity_type, state.service_content_configs),
      indexedAttributes = getIndexedAttributes(entityTypes, state.service_content_configs),
      randomTop = isEntitySearch ? state.search_random_top : null,
      batchSize = isEntitySearch ? state.search_batch_size : null;

    if (statsId) {
      searchFilters = state.search_view_filters;
    } else if (!isEntitySearch) {
      searchFilters = searchFilters?.concat(state.search_view_filters);
    }

    radarApi
      .loadFinancialEntities(
        entityTypes,
        startTimeText,
        endTimeText,
        batchSize,
        entityType,
        metricId,
        searchFilters,
        maxVersionOnly,
        anchorDateAttribute,
        displayColumns,
        indexedAttributes,
        randomTop,
        statsId,
        refreshData,
        isStaging(getState)
      )
      .then((result: any) => {
        dispatch(actionCreator.loadFinancialEntitiesCompleted(result.entities));

        appInsights.stopTrackEvent("LoadFinancialEntities", {
          entityTypes: entityTypes?.join(","),
          startTime: startTimeText,
          endTime: endTimeText,
          entityType,
          metricId,
          anchorDateAttribute,
          statsId,
          refreshData: refreshData.toString(),
        });
      })
      .catch((error) => dispatch(actionCreator.loadFinancialEntitiesFailed(error)));
  };

export const searchEntities = () => (dispatch: Dispatch, getState?: Function) => {
  var state = getState().modules,
    isMetricSearch = state.search_item_key === SearchItemKey.metricSearch;

  isMetricSearch && loadEntityMetrics()(dispatch, getState);
  loadFinancialEntities()(dispatch, getState);
};

export const selectEntityMetric =
  (entityType: string, metricId: string, refreshData: boolean = false) =>
  (dispatch: Dispatch, getState?: Function) => {
    dispatch(actionCreator.changeEntityMetric(entityType, metricId));

    if (metricId) {
      const state = getState(),
        startTime = state.modules.search_entity_start_time,
        endTime = state.modules.search_entity_end_time;

      loadFinancialEntities(startTime, endTime, entityType, metricId, refreshData)(dispatch, getState);
    }
  };

export const loadEntitySelection =
  (item: IEntity | IFinancialEntitySearch) => (dispatch: Dispatch, getState?: Function) => {
    if (item) {
      if (item["id"]) {
        loadEntityRelationships(item["entityType"], item["id"], item["version"])(dispatch, getState);
      } else if (item["metricId"]) {
        selectEntityMetric(item["entityType"], item["metricId"])(dispatch, getState);
      } else if (item["entityType"]) {
        dispatch(actionCreator.setSearchEntityType(item["entityType"]));
      } else {
        loadEntityMetrics(item["startTime"], item["endTime"])(dispatch, getState);
      }
    }
  };

export const loadPreviousEntitySelection = () => (dispatch: Dispatch, getState?: Function) => {
  let entitySelections = getState().modules.entity_selection_history,
    previousSelection = entitySelections && entitySelections.length > 1 && entitySelections[1];

  if (previousSelection) {
    dispatch(actionCreator.backToPreviousEntitySelection());
    loadEntitySelection(previousSelection)(dispatch, getState);
  }
};

export const loadControlTiles =
  (controlId: string, selectedValue: string = "", refreshData: boolean = false) =>
  (dispatch: Dispatch, getState?: Function) => {
    appInsights.startTrackEvent("LoadControlTiles");
    dispatch(actionCreator.requestControlTiles(controlId));
    dispatch(actionCreator.selectControlDrilldownValue(selectedValue));

    radarApi
      .fetchControlTiles(controlId, selectedValue, refreshData, isStaging(getState))
      .then((tileResults: any) => {
        dispatch(actionCreator.receivedControlTiles(controlId, tileResults));
        appInsights.stopTrackEvent("LoadControlTiles", { controlId, refreshData: refreshData.toString() });
      })
      .catch((error) => dispatch(actionCreator.requestControlTilesFailed(error)));
  };

export const loadControlResultHistory =
  (controlId: string, timeAgo: string = undefined, refreshData: boolean = false) =>
  (dispatch: Dispatch, getState?: Function) => {
    appInsights.startTrackEvent("LoadControlResultHistory");
    dispatch(actionCreator.requestControlResultHistory(controlId));

    radarApi
      .fetchControlResultHistory(controlId, timeAgo, refreshData, isStaging(getState))
      .then((results: any) => {
        dispatch(actionCreator.receivedControlResultHistory(controlId, results));
        appInsights.stopTrackEvent("LoadControlResultHistory", {
          controlId,
          timeAgo,
          refreshData: refreshData.toString(),
        });
      })
      .catch((error) => dispatch(actionCreator.requestControlResultHistoryFailed(error)));
  };

export const loadControlResultDetails =
  (controlId: string, controlResultId: string, refreshData: boolean = false) =>
  (dispatch: Dispatch, getState?: Function) => {
    appInsights.startTrackEvent("LoadControlResultDetails");
    dispatch(actionCreator.requestControlResultDetails(controlId, controlResultId));

    radarApi
      .fetchControlResultDetails(controlId, controlResultId, refreshData, isStaging(getState))
      .then((results: any) => {
        dispatch(actionCreator.receivedControlResultDetails(controlId, results, getState().app.login_user_info));
        appInsights.stopTrackEvent("LoadControlResultDetails", {
          controlId,
          controlResultId,
          refreshData: refreshData.toString(),
        });
      })
      .catch((error) =>
        dispatch(actionCreator.requestControlResultDetailsFailed(error, getState().app.login_user_info))
      );
  };

export const updateControlResultDetails =
  (controlId: string, controlResultStatus: IControlResultStatus) => (dispatch: Dispatch, getState?: Function) => {
    appInsights.startTrackEvent("UpdateControlResultDetails");
    dispatch(actionCreator.updateControlResultDetailsStarted());

    radarApi
      .updateControlResultDetails(controlId, controlResultStatus, isStaging(getState))
      .then((results: any) => {
        dispatch(actionCreator.updateControlResultDetailsCompleted(controlId, results, getState().app.login_user_info));
        appInsights.stopTrackEvent("UpdateControlResultDetails", {
          controlId,
          controlResultId: controlResultStatus.controlResultId,
        });
      })
      .catch((error) => dispatch(actionCreator.updateControlResultDetailsFailed(error)));
  };

export const updateControlComment = (comment: IComment) => (dispatch: Dispatch, getState?: Function) => {
  appInsights.startTrackEvent("UpdateControlComment");
  dispatch(actionCreator.updateControlCommentStarted(comment));

  radarApi
    .updateControlComment(comment, isStaging(getState))
    .then((comment: IComment) => {
      dispatch(actionCreator.updateControlCommentCompleted(comment));
      appInsights.stopTrackEvent("UpdateControlComment", {
        commentId: comment.commentId,
        isPurged: comment.isPurged.toString(),
      });
    })
    .catch((error) => dispatch(actionCreator.updateControlCommentFailed(error)));
};

export const updateControlLinkedItem = (linkedItem: ILinkedItem) => (dispatch: Dispatch, getState?: Function) => {
  appInsights.startTrackEvent("updateControlLinkedItem");
  dispatch(actionCreator.updateControlLinkedItemStarted(linkedItem));

  radarApi
    .updateControlLinkedItem(linkedItem, isStaging(getState))
    .then((linkedItem: ILinkedItem) => {
      dispatch(actionCreator.updateControlLinkedItemCompleted(linkedItem));
      appInsights.stopTrackEvent("updateControlLinkedItem", {
        linkedItemId: linkedItem.linkedItemId,
        isPurged: linkedItem.isPurged.toString(),
      });
    })
    .catch((error) => dispatch(actionCreator.updateControlLinkedItemFailed(error)));
};

export const deleteControlComment = (comment: IComment) => (dispatch: Dispatch, getState?: Function) => {
  const purgedComment = {
    ...comment,
    isPurged: true,
  };
  updateControlComment(purgedComment)(dispatch, getState);
};

export const deleteControlLinkedItem = (linkedItem: ILinkedItem) => (dispatch: Dispatch, getState?: Function) => {
  const purgedLinkedItem = {
    ...linkedItem,
    isPurged: true,
    settings: linkedItem.settings || "{}",
  };
  updateControlLinkedItem(purgedLinkedItem)(dispatch, getState);
};

export const loadControlConfig =
  (controlId: string, environment: string = null, refreshData: boolean = false) =>
  (dispatch: Dispatch, getState?: Function) => {
    loadConfigItem(radarApi.ConfigItemType.Controls, controlId, environment, refreshData)(dispatch, getState);
  };

export const loadControl =
  (controlId: string, refreshData: boolean = false, doNotLoadControlResult = false) =>
  (dispatch: Dispatch, getState?: Function) => {
    appInsights.startTrackEvent("LoadControl");
    dispatch(actionCreator.loadConfigItemStarted(controlId));

    radarApi
      .fetchControl(controlId, refreshData, isStaging(getState))
      .then((control: IControl) => {
        dispatch(actionCreator.loadConfigItemCompleted(control, radarApi.ConfigItemType.Controls));

        !doNotLoadControlResult && loadControlResult(control, refreshData)(dispatch, getState);

        appInsights.stopTrackEvent("LoadControl", { controlId });
      })
      .catch((error) => dispatch(actionCreator.loadConfigItemFailed(error)));
  };

export const loadControlResult =
  (control: IControl, refreshData: boolean = false) =>
  (dispatch: Dispatch, getState?: Function) => {
    let controlId = control.id;
    appInsights.startTrackEvent("LoadControlResult");
    dispatch(actionCreator.loadControlResultStarted(controlId));

    radarApi
      .fetchControlResult(controlId, refreshData, isStaging(getState))
      .then((controlResult: IControlResult) => {
        dispatch(actionCreator.loadControlResultCompleted(controlResult));

        let controlResultId = controlResult.controlResultId;

        const timeAgo = convertToTimeSpanInDays(defaultControlHistoryTimeSelectorValue);
        (refreshData || !controlResult.resultHistory) &&
          loadControlResultHistory(controlId, timeAgo, refreshData)(dispatch, getState);

        loadControlResultDetails(controlId, controlResultId, refreshData)(dispatch, getState);

        radarApi
          .fetchControlResultItems(controlId, controlResultId, undefined, refreshData, isStaging(getState))
          .then((result: any) => {
            dispatch(actionCreator.receivedControlResultItems(result?.lineItems, result?.totalCount));

            appInsights.stopTrackEvent("LoadControlResultItems", {
              controlId,
              controlResultId,
              refreshData: refreshData.toString(),
            });
          })
          .catch((error) => dispatch(actionCreator.requestControlResultItemsFailed(error)));

        let tiles = control.tiles || control.outputConfig.tiles;
        tiles?.length && loadControlTiles(controlId, "", refreshData)(dispatch, getState);

        appInsights.stopTrackEvent("LoadControlResult", { controlId });
      })
      .catch((error) => dispatch(actionCreator.loadControlResultFailed(error)));
  };

export const updateControl = (control: IControl, environment: string) => (dispatch: Dispatch, getState?: Function) =>
  updateConfigItem(radarApi.ConfigItemType.Controls, control, environment)(dispatch, getState);

export const deleteControl = (controlId: string) => (dispatch: Dispatch, getState?: Function) =>
  deleteConfigItem(radarApi.ConfigItemType.Controls, controlId)(dispatch, getState);

export const createControlIncident =
  (controlId: string, controlResultId: string) => (dispatch: Dispatch, getState?: Function) => {
    appInsights.startTrackEvent("CreateControlIncident");
    dispatch(actionCreator.createControlIncidentStarted());
    radarApi
      .createControlIncident(controlId, controlResultId, isStaging(getState))
      .then((result) => {
        dispatch(actionCreator.createControlIncidentCompleted(result));
        // Need to refresh the linked items due to the newly created IcM incident.
        loadControlResultDetails(controlId, controlResultId, true)(dispatch, getState);
        appInsights.stopTrackEvent("CreateControlIncident", { controlId, controlResultId });
      })
      .catch((error) => dispatch(actionCreator.createControlIncidentFailed(error)));
  };

export const loadReportGroups =
  (refreshData: boolean = false) =>
  (dispatch: Dispatch, getState?: Function) => {
    appInsights.startTrackEvent("LoadReportGroups");
    dispatch(actionCreator.loadConfigItemsStarted());

    radarApi
      .fetchReportGroups(refreshData, isStaging(getState))
      .then((reportGroups: any) => {
        dispatch(actionCreator.loadConfigItemsCompleted(radarApi.ConfigItemType.ReportGroups, reportGroups));
        appInsights.stopTrackEvent("LoadReportGroups");
      })
      .catch((error) => dispatch(actionCreator.loadConfigItemsFailed(error)));
  };

export const loadReportGroup =
  (reportGroupId: string, environment: string = null, refreshData: boolean = false) =>
  (dispatch: Dispatch, getState?: Function) => {
    appInsights.startTrackEvent("LoadReportGroup");
    dispatch(actionCreator.loadConfigItemStarted(reportGroupId));

    radarApi
      .fetchReportGroup(reportGroupId, refreshData, isStaging(getState))
      .then((reportGroup: any) => {
        dispatch(actionCreator.loadConfigItemCompleted(reportGroup));
        appInsights.stopTrackEvent("LoadReportGroup", {
          reportGroupId,
          reportGroupName: reportGroup && reportGroup.name,
        });
      })
      .catch((error) => dispatch(actionCreator.loadConfigItemFailed(error)));
  };

export const getReportGroup =
  (reportGroupId: string, reportId: string = null, refreshData: boolean = false) =>
  (dispatch: Dispatch, getState?: Function) => {
    appInsights.startTrackEvent("GetReportGroup");
    dispatch(actionCreator.getReportGroupStarted());

    radarApi
      .fetchReportGroup(reportGroupId, refreshData, isStaging(getState))
      .then((reportGroup: any) => {
        dispatch(actionCreator.getReportGroupCompleted(reportGroup));

        if (refreshData) {
          let reportIds = reportGroup.reportIds;

          if (reportIds && reportIds.length) {
            reportIds.forEach((rid, index) => {
              let shouldLoadReport = reportId ? rid === reportId : index === 0;
              if (shouldLoadReport) {
                loadReport(reportIds[index], null, true)(dispatch, getState);
              } else {
                dispatch(actionCreator.clearReportState(reportId));
              }
            });
          }
        }

        // For hideLeftPaneOnLoad is set, close the left pane.
        if (reportGroup.hideLeftPaneOnLoad && !getState().app.left_nav_collapsed) {
          dispatch(appActionCreator.toggleLeftNav());
        }

        appInsights.stopTrackEvent("GetReportGroup", {
          reportGroupId,
          reportId,
          reportGroupName: reportGroup && reportGroup.name,
        });
      })
      .catch((error) => dispatch(actionCreator.getReportGroupFailed(error)));
  };

export const updateReportGroup = (reportGroup: IReportGroup) => (dispatch: Dispatch, getState?: Function) => {
  appInsights.startTrackEvent("UpdateReportGroup");
  dispatch(actionCreator.updateConfigItemStarted());

  radarApi
    .updateReportGroup(reportGroup, isStaging(getState))
    .then((newReportGroup: any) => {
      dispatch(actionCreator.updateConfigItemCompleted(radarApi.ConfigItemType.ReportGroups, newReportGroup));
      loadReportGroups(true)(dispatch, getState);

      if (reportGroup.id === "new") {
        window.location.href = `/#${leftNavUrls.management.report}/${newReportGroup.id}`;
      }
      appInsights.stopTrackEvent("UpdateReportGroup", {
        reportGroupId: reportGroup.id,
        newReportGroupId: newReportGroup.id,
      });
    })
    .catch((error) => dispatch(actionCreator.updateConfigItemFailed(error)));
};

export const deleteReportGroup = (reportGroupId: string) => (dispatch: Dispatch, getState?: Function) => {
  appInsights.startTrackEvent("DeleteReportGroup");
  dispatch(actionCreator.deleteConfigItemStarted());

  radarApi
    .deleteReportGroup(reportGroupId, isStaging(getState))
    .then(() => {
      loadReportGroups(true)(dispatch, getState);
      dispatch(actionCreator.deleteConfigItemCompleted());
      appInsights.stopTrackEvent("DeleteReportGroup", { reportGroupId });
    })
    .catch((error) => dispatch(actionCreator.deleteConfigItemFailed(error)));
};

export const publishReportGroupConfigItem =
  (reportGroupId: string, logData: object) => (dispatch: Dispatch, getState?: Function) => {
    appInsights.startTrackEvent("PublishReportGroup");
    dispatch(actionCreator.publishConfigItemStarted(reportGroupId));
    radarApi
      .publishReportGroup(reportGroupId, logData, isStaging(getState))
      .then((configItem) => {
        dispatch(actionCreator.publishConfigItemCompleted(reportGroupId, configItem));
        appInsights.stopTrackEvent("PublishReportGroup", { reportGroupId });
      })
      .catch((error) => dispatch(actionCreator.publishConfigItemFailed(error)));
  };

export const loadReport =
  (reportId: string, filters?: IFilterItem[], refreshData?: boolean) => (dispatch: Dispatch, getState?: Function) => {
    appInsights.startTrackEvent("LoadReport");
    dispatch(actionCreator.getReportStarted(reportId));

    radarApi
      .fetchReport(reportId, filters, refreshData, isStaging(getState))
      .then((report: any) => {
        dispatch(actionCreator.getReportCompleted(report));
        appInsights.stopTrackEvent("LoadReport", { reportId, reportName: report && report.name });
      })
      .catch((error) => dispatch(actionCreator.getReportFailed(error)));
  };

export const loadReportTile =
  (reportId: string, tileIndex: number, value: string, refreshData?: boolean) =>
  (dispatch: Dispatch, getState?: Function) => {
    appInsights.startTrackEvent("LoadReportTile");
    dispatch(actionCreator.getReportTileStarted(reportId, tileIndex));

    radarApi
      .fetchReportTile(reportId, tileIndex, value, refreshData, isStaging(getState))
      .then((tile: any) => {
        dispatch(actionCreator.getReportTileCompleted(reportId, tileIndex, tile));
        appInsights.stopTrackEvent("LoadReportTile", { reportId, tileIndex: tileIndex.toString(), value });
      })
      .catch((error) => dispatch(actionCreator.getReportTileFailed(error)));
  };

export const loadReportTileColumn =
  (
    reportId: string,
    tileIndex: number,
    columnName: string,
    columnValue: string,
    filters?: IFilterItem[],
    refreshData?: boolean
  ) =>
  (dispatch: Dispatch, getState?: Function) => {
    appInsights.startTrackEvent("LoadReportTileColumn");
    dispatch(actionCreator.loadReportTileColumnStarted());

    radarApi
      .fetchReportTileColumnData(
        reportId,
        tileIndex,
        columnName,
        columnValue,
        filters,
        refreshData,
        isStaging(getState)
      )
      .then(() => {
        // Note: This is currently designed for triggering data update to data source. We assume no returned data are required to be displayed. Will refactor if this requirement changes.

        let selectedFilters = getState().modules.selected_report_filters || [];
        loadReport(reportId, selectedFilters, refreshData)(dispatch, getState);

        appInsights.stopTrackEvent("LoadReportTileColumn", {
          reportId,
          tileIndex: tileIndex.toString(),
          columnName,
          columnValue,
        });
      })
      .catch((error) => dispatch(actionCreator.loadReportTileColumnFailed(error)));
  };

export const submitReportTileData =
  (reportId: string, tileIndex: number, fieldName: string, fieldValue: string, filters?: IFilterItem[]) =>
  (dispatch: Dispatch, getState?: Function) => {
    appInsights.startTrackEvent("SubmitReportTileData");
    dispatch(actionCreator.loadReportTileColumnStarted());

    radarApi
      .submitReportTileData(reportId, tileIndex, fieldName, fieldValue, filters, true, isStaging(getState))
      .then(() => {
        // Note: This is currently designed for triggering data update to data source. We assume no returned data are required to be displayed. Will refactor if this requirement changes.

        let selectedFilters = getState().modules.selected_report_filters || [];
        loadReport(reportId, selectedFilters, true)(dispatch, getState);

        appInsights.stopTrackEvent("SubmitReportTileData", {
          reportId,
          tileIndex: tileIndex.toString(),
          fieldName,
          fieldValue,
        });
      })
      .catch((error) => dispatch(actionCreator.loadReportTileColumnFailed(error)));
  };

export const reloadReportWithFilter =
  (reportId: string, filters: IFilterItem[], doNotLoadReport?: boolean, refreshData?: boolean) =>
  (dispatch: Dispatch, getState?: Function) => {
    appInsights.startTrackEvent("ReloadReportWithFilter");

    let selectedFilters = getState().modules.selected_report_filters || [],
      updatedFilters = updateFilters(selectedFilters, filters);

    dispatch(actionCreator.updateReportFilters(updatedFilters));

    updatedFilters.forEach((filter) => {
      filter.selected = filters && filters.some((f) => f.name === filter.name);
      return filter;
    });

    !doNotLoadReport && loadReport(reportId, updatedFilters, refreshData)(dispatch, getState);
    appInsights.stopTrackEvent("ReloadReportWithFilter", { reportId, filters: JSON.stringify(filters) });
  };

export const updateFilters = (filters: IFilterItem[] = [], newFilters: IFilterItem[]): IFilterItem[] => {
  let filtersCopy = filters.slice();

  newFilters &&
    newFilters.forEach((newFilter) => {
      let { name, value } = newFilter,
        filterIndex = filtersCopy.findIndex((filter) => filter.name === name);

      if (filterIndex < 0) {
        filtersCopy.push({ name, value });
      } else {
        filtersCopy.splice(filterIndex, 1, { name, value });
      }
    });

  return filtersCopy;
};

export const publishComplianceConfigItem =
  (complianceId: string, logData: object) => (dispatch: Dispatch, getState?: Function) =>
    publishConfigItem(radarApi.ConfigItemType.ComplianceDocs, complianceId, logData)(dispatch, getState);

export const unpublishComplianceConfigItem =
  (complianceId: string, logData: object) => (dispatch: Dispatch, getState?: Function) =>
    unpublishConfigItem(radarApi.ConfigItemType.ComplianceDocs, complianceId, logData)(dispatch, getState);

export const revertComplianceConfigItem = (complianceId: string) => (dispatch: Dispatch, getState?: Function) =>
  revertConfigItem(radarApi.ConfigItemType.ComplianceDocs, complianceId)(dispatch, getState);

export const publishConfigDefsConfigItem = (id: string, logData: object) => (dispatch: Dispatch, getState?: Function) =>
  publishConfigItem(radarApi.ConfigItemType.ConfigDefs, id, logData)(dispatch, getState);

export const unpublishConfigDefsConfigItem =
  (id: string, logData: object) => (dispatch: Dispatch, getState?: Function) =>
    unpublishConfigItem(radarApi.ConfigItemType.ConfigDefs, id, logData)(dispatch, getState);

export const revertConfigDefsConfigItem = (id: string) => (dispatch: Dispatch, getState?: Function) =>
  revertConfigItem(radarApi.ConfigItemType.ConfigDefs, id)(dispatch, getState);

export const publishControlConfigItem =
  (controlId: string, logData: object) => (dispatch: Dispatch, getState?: Function) =>
    publishConfigItem(radarApi.ConfigItemType.Controls, controlId, logData)(dispatch, getState);

export const unpublishControlConfigItem =
  (controlId: string, logData: object) => (dispatch: Dispatch, getState?: Function) =>
    unpublishConfigItem(radarApi.ConfigItemType.Controls, controlId, logData)(dispatch, getState);

export const revertControlConfigItem = (controlId: string) => (dispatch: Dispatch, getState?: Function) =>
  revertConfigItem(radarApi.ConfigItemType.Controls, controlId)(dispatch, getState);

export const publishIncidentViewConfigItem =
  (incidentViewId: string, logData: object) => (dispatch: Dispatch, getState?: Function) =>
    publishConfigItem(radarApi.ConfigItemType.IncidentViews, incidentViewId, logData)(dispatch, getState);

export const unpublishIncidentViewConfigItem =
  (incidentViewId: string, logData: object) => (dispatch: Dispatch, getState?: Function) =>
    unpublishConfigItem(radarApi.ConfigItemType.IncidentViews, incidentViewId, logData)(dispatch, getState);

export const revertIncidentViewConfigItem = (incidentViewId: string) => (dispatch: Dispatch, getState?: Function) =>
  revertConfigItem(radarApi.ConfigItemType.IncidentViews, incidentViewId)(dispatch, getState);

export const unpublishReportGroupConfigItem =
  (reportGroupId: string, logData: object) => (dispatch: Dispatch, getState?: Function) =>
    unpublishConfigItem(radarApi.ConfigItemType.ReportGroups, reportGroupId, logData)(dispatch, getState);

export const revertReportGroupConfigItem = (reportGroupId: string) => (dispatch: Dispatch, getState?: Function) =>
  revertConfigItem(radarApi.ConfigItemType.ReportGroups, reportGroupId)(dispatch, getState);

export const loadAnomalyTestConfigItems =
  (refreshData: boolean = false) =>
  (dispatch: Dispatch, getState?: Function) =>
    loadConfigItems(radarApi.ConfigItemType.AnomalyTests, refreshData)(dispatch, getState);

export const loadAnomalyTestConfigItem =
  (
    appSettingId: string,
    environment: string = null,
    refreshData: boolean = false,
    doNotUpdateConfigValue: boolean = false
  ) =>
  (dispatch: Dispatch, getState?: Function) =>
    loadConfigItem(
      radarApi.ConfigItemType.AnomalyTests,
      appSettingId,
      environment,
      refreshData,
      doNotUpdateConfigValue
    )(dispatch, getState);

export const updateAnomalyTestConfigItem = (anomalyTest: IAnomalyTest) => (dispatch: Dispatch, getState?: Function) =>
  updateConfigItem(radarApi.ConfigItemType.AnomalyTests, anomalyTest)(dispatch, getState);

export const deleteAnomalyTestConfigItem = (anomalyTestId: string) => (dispatch: Dispatch, getState?: Function) =>
  deleteConfigItem(radarApi.ConfigItemType.AnomalyTests, anomalyTestId)(dispatch, getState);

export const publishAnomalyTestConfigItem =
  (anomalyTestId: string, logData: object) => (dispatch: Dispatch, getState?: Function) =>
    publishConfigItem(radarApi.ConfigItemType.AnomalyTests, anomalyTestId, logData)(dispatch, getState);

export const unpublishAnomalyTestConfigItem =
  (anomalyTestId: string, logData: object) => (dispatch: Dispatch, getState?: Function) =>
    unpublishConfigItem(radarApi.ConfigItemType.AnomalyTests, anomalyTestId, logData)(dispatch, getState);

export const revertAnomalyTestConfigItem = (anomalyTestId: string) => (dispatch: Dispatch, getState?: Function) =>
  revertConfigItem(radarApi.ConfigItemType.AnomalyTests, anomalyTestId)(dispatch, getState);

export const loadAppSettingConfigItems =
  (refreshData: boolean = false) =>
  (dispatch: Dispatch, getState?: Function) =>
    loadConfigItems(radarApi.ConfigItemType.AppSettings, refreshData)(dispatch, getState);

export const loadAppSettingConfigItem =
  (
    appSettingId: string,
    environment: string = null,
    refreshData: boolean = false,
    doNotUpdateConfigValue: boolean = false
  ) =>
  (dispatch: Dispatch, getState?: Function) =>
    loadConfigItem(
      radarApi.ConfigItemType.AppSettings,
      appSettingId,
      environment,
      refreshData,
      doNotUpdateConfigValue
    )(dispatch, getState);

export const updateAppSettingConfigItem = (appSetting: IAppSetting) => (dispatch: Dispatch, getState?: Function) =>
  updateConfigItem(radarApi.ConfigItemType.AppSettings, appSetting)(dispatch, getState);

export const deleteAppSettingConfigItem = (appSettingId: string) => (dispatch: Dispatch, getState?: Function) =>
  deleteConfigItem(radarApi.ConfigItemType.AppSettings, appSettingId)(dispatch, getState);

export const publishAppSettingConfigItem =
  (appSettingId: string, logData: object) => (dispatch: Dispatch, getState?: Function) =>
    publishConfigItem(radarApi.ConfigItemType.AppSettings, appSettingId, logData)(dispatch, getState);

export const unpublishAppSettingConfigItem =
  (appSettingId: string, logData: object) => (dispatch: Dispatch, getState?: Function) =>
    unpublishConfigItem(radarApi.ConfigItemType.AppSettings, appSettingId, logData)(dispatch, getState);

export const revertAppSettingConfigItem = (appSettingId: string) => (dispatch: Dispatch, getState?: Function) =>
  revertConfigItem(radarApi.ConfigItemType.AppSettings, appSettingId)(dispatch, getState);

export const loadCollectorServiceConfigItems =
  (refreshData: boolean = false) =>
  (dispatch: Dispatch, getState?: Function) =>
    loadConfigItems(radarApi.ConfigItemType.CollectorService, refreshData)(dispatch, getState);

export const loadCollectorServiceConfigItem =
  (
    collectorServiceId: string,
    environment: string = "",
    refreshData: boolean = false,
    doNotUpdateConfigValue?: boolean,
    callback: (configItem: any) => void = null
  ) =>
  (dispatch: Dispatch, getState?: Function) => {
    loadConfigItemByEnvironment(
      radarApi.ConfigItemType.CollectorService,
      collectorServiceId,
      doNotUpdateConfigValue,
      environment,
      refreshData,
      callback
    )(dispatch, getState);
  };

export const updateCollectorServiceConfigItem =
  (collectorService: ICollectorService) => (dispatch: Dispatch, getState?: Function) =>
    updateConfigItem(radarApi.ConfigItemType.CollectorService, collectorService)(dispatch, getState);

export const deleteCollectorServiceConfigItem =
  (collectorServiceId: string) => (dispatch: Dispatch, getState?: Function) =>
    deleteConfigItem(radarApi.ConfigItemType.CollectorService, collectorServiceId)(dispatch, getState);

export const publishCollectorServiceConfigItem =
  (collectorServiceId: string, logData: object) => (dispatch: Dispatch, getState?: Function) =>
    publishConfigItem(radarApi.ConfigItemType.CollectorService, collectorServiceId, logData)(dispatch, getState);

export const unpublishCollectorServiceConfigItem =
  (collectorServiceId: string, logData: object) => (dispatch: Dispatch, getState?: Function) =>
    unpublishConfigItem(radarApi.ConfigItemType.CollectorService, collectorServiceId, logData)(dispatch, getState);

export const revertCollectorServiceConfigItem =
  (collectorServiceId: string) => (dispatch: Dispatch, getState?: Function) =>
    revertConfigItem(radarApi.ConfigItemType.CollectorService, collectorServiceId)(dispatch, getState);

export const loadControlViewConfigItems =
  (refreshData: boolean = false) =>
  (dispatch: Dispatch, getState?: Function) =>
    loadConfigItems(radarApi.ConfigItemType.ControlViews, refreshData)(dispatch, getState);

export const loadControlViewConfigItem =
  (controlViewId: string, environment: string = null, refreshData: boolean = false) =>
  (dispatch: Dispatch, getState?: Function) =>
    loadConfigItem(radarApi.ConfigItemType.ControlViews, controlViewId, environment, refreshData)(dispatch, getState);

export const loadComplianceConfigItem =
  (
    complianceId: string,
    environment: string = null,
    refreshData: boolean = false,
    doNotUpdateConfigValue: boolean = false,
    callback: (configItem) => void
  ) =>
  (dispatch: Dispatch, getState?: Function) =>
    loadConfigItem(
      radarApi.ConfigItemType.ComplianceDocs,
      complianceId,
      environment,
      refreshData,
      doNotUpdateConfigValue,
      callback
    )(dispatch, getState);

export const updateComplianceViewConfigItem =
  (complianceView: IConfigItem) => (dispatch: Dispatch, getState?: Function) =>
    updateConfigItem(radarApi.ConfigItemType.ComplianceDocs, complianceView)(dispatch, getState);

export const loadConfigDefsConfigItem =
  (configDocId: string, environment: string = null, refreshData: boolean = false) =>
  (dispatch: Dispatch, getState?: Function) =>
    loadConfigItem(radarApi.ConfigItemType.ConfigDefs, configDocId, environment, refreshData)(dispatch, getState);

export const updateConfigDefsViewConfigItem =
  (configDefView: IConfigItem) => (dispatch: Dispatch, getState?: Function) =>
    updateConfigItem(radarApi.ConfigItemType.ConfigDefs, configDefView)(dispatch, getState);

export const updateControlViewConfigItem = (controlView: IControlView) => (dispatch: Dispatch, getState?: Function) =>
  updateConfigItem(radarApi.ConfigItemType.ControlViews, controlView)(dispatch, getState);

export const deleteControlViewConfigItem = (controlViewId: string) => (dispatch: Dispatch, getState?: Function) =>
  deleteConfigItem(radarApi.ConfigItemType.ControlViews, controlViewId)(dispatch, getState);

export const publishControlViewConfigItem =
  (controlViewId: string, logData: object) => (dispatch: Dispatch, getState?: Function) =>
    publishConfigItem(radarApi.ConfigItemType.ControlViews, controlViewId, logData)(dispatch, getState);

export const unpublishControlViewConfigItem =
  (controlViewId: string, logData: object) => (dispatch: Dispatch, getState?: Function) =>
    unpublishConfigItem(radarApi.ConfigItemType.ControlViews, controlViewId, logData)(dispatch, getState);

export const revertControlViewConfigItem = (controlViewId: string) => (dispatch: Dispatch, getState?: Function) =>
  revertConfigItem(radarApi.ConfigItemType.ControlViews, controlViewId)(dispatch, getState);

export const loadEntityConfigItems =
  (refreshData: boolean = false) =>
  (dispatch: Dispatch, getState?: Function) =>
    loadConfigItems(radarApi.ConfigItemType.Entities, refreshData)(dispatch, getState);

export const loadEntityConfigItem =
  (entityId: string, environment: string = null, refreshData: boolean = false) =>
  (dispatch: Dispatch, getState?: Function) =>
    loadConfigItem(radarApi.ConfigItemType.Entities, entityId, environment, refreshData)(dispatch, getState);

export const updateEntityConfigItem = (entity: IEntity) => (dispatch: Dispatch, getState?: Function) =>
  updateConfigItem(radarApi.ConfigItemType.Entities, entity)(dispatch, getState);

export const deleteEntityConfigItem = (entityId: string) => (dispatch: Dispatch, getState?: Function) =>
  deleteConfigItem(radarApi.ConfigItemType.Entities, entityId)(dispatch, getState);

export const publishEntityConfigItem =
  (entityId: string, logData: object) => (dispatch: Dispatch, getState?: Function) =>
    publishConfigItem(radarApi.ConfigItemType.Entities, entityId, logData)(dispatch, getState);

export const unpublishEntityConfigItem =
  (entityId: string, logData: object) => (dispatch: Dispatch, getState?: Function) =>
    unpublishConfigItem(radarApi.ConfigItemType.Entities, entityId, logData)(dispatch, getState);

export const revertEntityConfigItem = (entityId: string) => (dispatch: Dispatch, getState?: Function) =>
  revertConfigItem(radarApi.ConfigItemType.Entities, entityId)(dispatch, getState);

export const loadEntityViewConfigItems =
  (loadMetrics: boolean = false, selectedEntityViewId: string = null, refreshData: boolean = false) =>
  (dispatch: Dispatch, getState?: Function) => {
    appInsights.startTrackEvent("LoadConfigItems");
    dispatch(actionCreator.loadConfigItemsStarted());

    radarApi
      .fetchConfigItems(radarApi.ConfigItemType.EntityViews, refreshData, isStaging(getState))
      .then((configItems: any) => {
        dispatch(actionCreator.loadConfigItemsCompleted(radarApi.ConfigItemType.EntityViews, configItems));

        if (selectedEntityViewId && selectedEntityViewId !== getState().modules.selected_entity_view_id) {
          dispatch(actionCreator.selectEntityView(selectedEntityViewId));
        }
        if (loadMetrics) {
          loadEntityMetrics(null, null, refreshData)(dispatch, getState);
        }

        appInsights.stopTrackEvent("LoadConfigItems", { configItemType: radarApi.ConfigItemType.EntityViews });
      })
      .catch((error) => dispatch(actionCreator.loadConfigItemsFailed(error)));
  };

export const loadEntityViewConfigItem =
  (entityViewId: string, environment: string = null, refreshData: boolean = false) =>
  (dispatch: Dispatch, getState?: Function) =>
    loadConfigItem(radarApi.ConfigItemType.EntityViews, entityViewId, environment, refreshData)(dispatch, getState);

export const updateEntityViewConfigItem = (entityView: IEntityView) => (dispatch: Dispatch, getState?: Function) =>
  updateConfigItem(radarApi.ConfigItemType.EntityViews, entityView)(dispatch, getState);

export const deleteEntityViewConfigItem = (entityViewId: string) => (dispatch: Dispatch, getState?: Function) =>
  deleteConfigItem(radarApi.ConfigItemType.EntityViews, entityViewId)(dispatch, getState);

export const publishEntityViewConfigItem =
  (entityViewId: string, logData: object) => (dispatch: Dispatch, getState?: Function) =>
    publishConfigItem(radarApi.ConfigItemType.EntityViews, entityViewId, logData)(dispatch, getState);

export const unpublishEntityViewConfigItem =
  (entityViewId: string, logData: object) => (dispatch: Dispatch, getState?: Function) =>
    unpublishConfigItem(radarApi.ConfigItemType.EntityViews, entityViewId, logData)(dispatch, getState);

export const revertEntityViewConfigItem = (entityViewId: string) => (dispatch: Dispatch, getState?: Function) =>
  revertConfigItem(radarApi.ConfigItemType.EntityViews, entityViewId)(dispatch, getState);

export const loadServiceContentConfigItems =
  (refreshData: boolean = false, callback: Function = null) =>
  (dispatch: Dispatch, getState?: Function) =>
    loadConfigItems(radarApi.ConfigItemType.ServiceContent, refreshData, false, callback)(dispatch, getState);

export const loadComplianceConfigItems =
  (refreshData: boolean = false, callback: Function = null) =>
  (dispatch: Dispatch, getState?: Function) =>
    loadConfigItems(radarApi.ConfigItemType.ComplianceDocs, refreshData, false, callback)(dispatch, getState);

export const loadConfigDefsConfigItems =
  (refreshData: boolean = false, callback: Function = null) =>
  (dispatch: Dispatch, getState?: Function) =>
    loadConfigItems(radarApi.ConfigItemType.ConfigDefs, refreshData, false, callback)(dispatch, getState);

export const loadConfigDefinition =
  (configType: radarApi.ConfigItemType, refreshData: boolean = false, callback: (configDef: IConfigDefs) => void) =>
  (dispatch: Dispatch, getState?: Function) => {
    const localCallback = (configItems: IConfigDefs[]) => {
      let found: boolean = false;
      configItems?.forEach((configDef) => {
        if (!found && configDef?.configType === configType) {
          found = true;
          const convertedDef = convertFromConfigToField(configDef);
          dispatch(actionCreator.loadConfigDefsCompleted(convertedDef));
          callback && callback(convertedDef);
        }
      });
    };
    loadConfigItems(radarApi.ConfigItemType.ConfigDefs, refreshData, true, localCallback)(dispatch, getState);
  };

export const loadServiceContentConfigItem =
  (
    serviceContentId: string,
    environment: string = "",
    refreshData: boolean = false,
    doNotUpdateConfigValue?: boolean,
    callback: (configItem: any) => void = null
  ) =>
  (dispatch: Dispatch, getState?: Function) => {
    loadConfigItemByEnvironment(
      radarApi.ConfigItemType.ServiceContent,
      serviceContentId,
      doNotUpdateConfigValue,
      environment,
      refreshData,
      callback
    )(dispatch, getState);
  };

export const updateServiceContentConfigItem =
  (serviceContent: IEventSubscription, environment: string) => (dispatch: Dispatch, getState?: Function) =>
    updateConfigItem(radarApi.ConfigItemType.ServiceContent, serviceContent, environment)(dispatch, getState);

export const deleteServiceContentConfigItem = (serviceContentId: string) => (dispatch: Dispatch, getState?: Function) =>
  deleteConfigItem(radarApi.ConfigItemType.ServiceContent, serviceContentId)(dispatch, getState);

export const publishServiceContentConfigItem =
  (serviceContentId: string, logData: object) => (dispatch: Dispatch, getState?: Function) =>
    publishConfigItem(radarApi.ConfigItemType.ServiceContent, serviceContentId, logData)(dispatch, getState);

export const unpublishServiceContentConfigItem =
  (serviceContentId: string, logData: object) => (dispatch: Dispatch, getState?: Function) =>
    unpublishConfigItem(radarApi.ConfigItemType.ServiceContent, serviceContentId, logData)(dispatch, getState);

export const revertServiceContentConfigItem = (serviceContentId: string) => (dispatch: Dispatch, getState?: Function) =>
  revertConfigItem(radarApi.ConfigItemType.ServiceContent, serviceContentId)(dispatch, getState);

export const deleteComplianceConfigItem = (complianceConfigId: string) => (dispatch: Dispatch, getState?: Function) =>
  deleteConfigItem(radarApi.ConfigItemType.ComplianceDocs, complianceConfigId)(dispatch, getState);

export const deleteConfigDefsConfigItem = (configDefsId: string) => (dispatch: Dispatch, getState?: Function) =>
  deleteConfigItem(radarApi.ConfigItemType.ConfigDefs, configDefsId)(dispatch, getState);

export const loadSubscriptionConfigItems =
  (refreshData: boolean = false) =>
  (dispatch: Dispatch, getState?: Function) =>
    loadConfigItems(radarApi.ConfigItemType.Subscriptions, refreshData)(dispatch, getState);

export const loadSubscriptionConfigItem =
  (subscriptionId: string, environment: string = null, refreshData: boolean = false) =>
  (dispatch: Dispatch, getState?: Function) =>
    loadConfigItem(radarApi.ConfigItemType.Subscriptions, subscriptionId, environment, refreshData)(dispatch, getState);

export const updateSubscriptionConfigItem =
  (subscription: IEventSubscription) => (dispatch: Dispatch, getState?: Function) =>
    updateConfigItem(radarApi.ConfigItemType.Subscriptions, subscription)(dispatch, getState);

export const deleteSubscriptionConfigItem = (subscriptionId: string) => (dispatch: Dispatch, getState?: Function) =>
  deleteConfigItem(radarApi.ConfigItemType.Subscriptions, subscriptionId)(dispatch, getState);

export const publishSubscriptionConfigItem =
  (subscriptionId: string, logData: object) => (dispatch: Dispatch, getState?: Function) =>
    publishConfigItem(radarApi.ConfigItemType.Subscriptions, subscriptionId, logData)(dispatch, getState);

export const unpublishSubscriptionConfigItem =
  (subscriptionId: string, logData: object) => (dispatch: Dispatch, getState?: Function) =>
    unpublishConfigItem(radarApi.ConfigItemType.Subscriptions, subscriptionId, logData)(dispatch, getState);

export const revertSubscriptionConfigItem = (subscriptionId: string) => (dispatch: Dispatch, getState?: Function) =>
  revertConfigItem(radarApi.ConfigItemType.Subscriptions, subscriptionId)(dispatch, getState);

export const loadDevOpsViewConfigItems =
  (refreshData: boolean = false) =>
  (dispatch: Dispatch, getState?: Function) =>
    loadConfigItems(radarApi.ConfigItemType.DevOpsViews, refreshData)(dispatch, getState);

export const loadDevOpsViewConfigItem =
  (devOpsViewId: string, environment: string = null, refreshData: boolean = false) =>
  (dispatch: Dispatch, getState?: Function) =>
    loadConfigItem(radarApi.ConfigItemType.DevOpsViews, devOpsViewId, environment, refreshData)(dispatch, getState);

export const updateDevOpsViewConfigItem = (devOpsView: IDevOpsView) => (dispatch: Dispatch, getState?: Function) =>
  updateConfigItem(radarApi.ConfigItemType.DevOpsViews, devOpsView)(dispatch, getState);

export const deleteDevOpsViewConfigItem = (devOpsViewId: string) => (dispatch: Dispatch, getState?: Function) =>
  deleteConfigItem(radarApi.ConfigItemType.DevOpsViews, devOpsViewId)(dispatch, getState);

export const publishDevOpsViewConfigItem =
  (devOpsViewId: string, logData: object) => (dispatch: Dispatch, getState?: Function) =>
    publishConfigItem(radarApi.ConfigItemType.DevOpsViews, devOpsViewId, logData)(dispatch, getState);

export const unpublishDevOpsViewConfigItem =
  (devOpsViewId: string, logData: object) => (dispatch: Dispatch, getState?: Function) =>
    unpublishConfigItem(radarApi.ConfigItemType.DevOpsViews, devOpsViewId, logData)(dispatch, getState);

export const revertDevOpsViewConfigItem = (devOpsViewId: string) => (dispatch: Dispatch, getState?: Function) =>
  revertConfigItem(radarApi.ConfigItemType.DevOpsViews, devOpsViewId)(dispatch, getState);

export const loadChecklistConfigItems =
  (refreshData: boolean = false) =>
  (dispatch: Dispatch, getState?: Function) =>
    loadConfigItems(radarApi.ConfigItemType.ChecklistConfig, refreshData)(dispatch, getState);

export const loadChecklistConfigItem =
  (checklistId: string, environment: string = null, refreshData: boolean = false) =>
  (dispatch: Dispatch, getState?: Function) =>
    loadConfigItem(radarApi.ConfigItemType.ChecklistConfig, checklistId, environment, refreshData)(dispatch, getState);

export const updateChecklistConfigItem = (checklist: IChecklist) => (dispatch: Dispatch, getState?: Function) =>
  updateConfigItem(radarApi.ConfigItemType.ChecklistConfig, checklist)(dispatch, getState);

export const deleteChecklistConfigItem = (checklistId: string) => (dispatch: Dispatch, getState?: Function) =>
  deleteConfigItem(radarApi.ConfigItemType.ChecklistConfig, checklistId)(dispatch, getState);

export const publishChecklistConfigItem =
  (checklistId: string, logData: object) => (dispatch: Dispatch, getState?: Function) =>
    publishConfigItem(radarApi.ConfigItemType.ChecklistConfig, checklistId, logData)(dispatch, getState);

export const unpublishChecklistConfigItem =
  (checklistId: string, logData: object) => (dispatch: Dispatch, getState?: Function) =>
    unpublishConfigItem(radarApi.ConfigItemType.ChecklistConfig, checklistId, logData)(dispatch, getState);

export const revertChecklistConfigItem = (checklistId: string) => (dispatch: Dispatch, getState?: Function) =>
  revertConfigItem(radarApi.ConfigItemType.ChecklistConfig, checklistId)(dispatch, getState);

export const loadEventConfigItems =
  (refreshData: boolean = false, doNotUpdateConfigItems: boolean = false) =>
  (dispatch: Dispatch, getState?: Function) =>
    loadConfigItems(radarApi.ConfigItemType.Events, refreshData, doNotUpdateConfigItems)(dispatch, getState);

export const loadEventConfigItem =
  (eventId: string, environment: string = null, refreshData: boolean = false) =>
  (dispatch: Dispatch, getState?: Function) =>
    loadConfigItem(radarApi.ConfigItemType.Events, eventId, environment, refreshData)(dispatch, getState);

export const updateEventConfigItem = (event: IEvent) => (dispatch: Dispatch, getState?: Function) =>
  updateConfigItem(radarApi.ConfigItemType.Events, event)(dispatch, getState);

export const deleteEventConfigItem = (eventId: string) => (dispatch: Dispatch, getState?: Function) =>
  deleteConfigItem(radarApi.ConfigItemType.Events, eventId)(dispatch, getState);

export const publishEventConfigItem = (eventId: string, logData: object) => (dispatch: Dispatch, getState?: Function) =>
  publishConfigItem(radarApi.ConfigItemType.Events, eventId, logData)(dispatch, getState);

export const unpublishEventConfigItem =
  (eventId: string, logData: object) => (dispatch: Dispatch, getState?: Function) =>
    unpublishConfigItem(radarApi.ConfigItemType.Events, eventId, logData)(dispatch, getState);

export const revertEventConfigItem = (eventId: string) => (dispatch: Dispatch, getState?: Function) =>
  revertConfigItem(radarApi.ConfigItemType.Events, eventId)(dispatch, getState);

export const loadEntityTraceViewConfigItems =
  (refreshData: boolean = false) =>
  (dispatch: Dispatch, getState?: Function) =>
    loadConfigItems(radarApi.ConfigItemType.EntityTraceViews, refreshData)(dispatch, getState);

export const loadEntityTraceViewConfigItem =
  (entityTraceViewId: string, environment: string = null, refreshData: boolean = false) =>
  (dispatch: Dispatch, getState?: Function) =>
    loadConfigItem(
      radarApi.ConfigItemType.EntityTraceViews,
      entityTraceViewId,
      environment,
      refreshData
    )(dispatch, getState);

export const updateEntityTraceViewConfigItem =
  (entityTraceView: IEntityTraceView) => (dispatch: Dispatch, getState?: Function) =>
    updateConfigItem(radarApi.ConfigItemType.EntityTraceViews, entityTraceView)(dispatch, getState);

export const deleteEntityTraceViewConfigItem =
  (entityTraceViewId: string) => (dispatch: Dispatch, getState?: Function) =>
    deleteConfigItem(radarApi.ConfigItemType.EntityTraceViews, entityTraceViewId)(dispatch, getState);

export const publishEntityTraceViewConfigItem =
  (entityTraceViewId: string, logData: object) => (dispatch: Dispatch, getState?: Function) =>
    publishConfigItem(radarApi.ConfigItemType.EntityTraceViews, entityTraceViewId, logData)(dispatch, getState);

export const unpublishEntityTraceViewConfigItem =
  (entityTraceViewId: string, logData: object) => (dispatch: Dispatch, getState?: Function) =>
    unpublishConfigItem(radarApi.ConfigItemType.EntityTraceViews, entityTraceViewId, logData)(dispatch, getState);

export const revertEntityTraceViewConfigItem =
  (entityTraceViewId: string) => (dispatch: Dispatch, getState?: Function) =>
    revertConfigItem(radarApi.ConfigItemType.EntityTraceViews, entityTraceViewId)(dispatch, getState);

export const loadTeamConfigItems =
  (refreshData: boolean = false, callback: Function = null) =>
  (dispatch: Dispatch, getState?: Function) =>
    loadConfigItems(radarApi.ConfigItemType.Teams, refreshData, false, callback)(dispatch, getState);

export const loadTeamConfigItem =
  (teamId: string, environment: string = null, refreshData: boolean = false) =>
  (dispatch: Dispatch, getState?: Function) =>
    loadConfigItem(radarApi.ConfigItemType.Teams, teamId, environment, refreshData)(dispatch, getState);

export const updateTeamConfigItem = (team: ITeam) => (dispatch: Dispatch, getState?: Function) =>
  updateConfigItem(radarApi.ConfigItemType.Teams, team)(dispatch, getState);

export const deleteTeamConfigItem = (teamId: string) => (dispatch: Dispatch, getState?: Function) =>
  deleteConfigItem(radarApi.ConfigItemType.Teams, teamId)(dispatch, getState);

export const publishTeamConfigItem = (teamId: string, logData: object) => (dispatch: Dispatch, getState?: Function) =>
  publishConfigItem(radarApi.ConfigItemType.Teams, teamId, logData)(dispatch, getState);

export const unpublishTeamConfigItem = (teamId: string, logData: object) => (dispatch: Dispatch, getState?: Function) =>
  unpublishConfigItem(radarApi.ConfigItemType.Teams, teamId, logData)(dispatch, getState);

export const revertTeamConfigItem = (teamId: string) => (dispatch: Dispatch, getState?: Function) =>
  revertConfigItem(radarApi.ConfigItemType.Teams, teamId)(dispatch, getState);

export const loadStreamScheduleConfigItems =
  (refreshData: boolean = false, callback: Function = null) =>
  (dispatch: Dispatch, getState?: Function) =>
    loadConfigItems(radarApi.ConfigItemType.StreamSchedules, refreshData, false, callback)(dispatch, getState);

export const loadStreamScheduleConfigItem =
  (streamScheduleId: string, environment: string = null, refreshData: boolean = false) =>
  (dispatch: Dispatch, getState?: Function) =>
    loadConfigItem(
      radarApi.ConfigItemType.StreamSchedules,
      streamScheduleId,
      environment,
      refreshData
    )(dispatch, getState);

export const updateStreamScheduleConfigItem = (streamSchedule) => (dispatch: Dispatch, getState?: Function) =>
  updateConfigItem(radarApi.ConfigItemType.StreamSchedules, streamSchedule)(dispatch, getState);

export const deleteStreamScheduleConfigItem = (streamScheduleId: string) => (dispatch: Dispatch, getState?: Function) =>
  deleteConfigItem(radarApi.ConfigItemType.StreamSchedules, streamScheduleId)(dispatch, getState);

export const loadStreamRunConfigItems =
  (refreshData: boolean = false, callback: Function = null) =>
  (dispatch: Dispatch, getState?: Function) =>
    loadConfigItems(radarApi.ConfigItemType.StreamRuns, refreshData, false, callback)(dispatch, getState);

export const loadStreamRunConfigItem =
  (streamRunId: string, environment: string = null, refreshData: boolean = false) =>
  (dispatch: Dispatch, getState?: Function) =>
    loadConfigItem(radarApi.ConfigItemType.StreamRuns, streamRunId, environment, refreshData)(dispatch, getState);

export const updateStreamRunConfigItem = (streamRun) => (dispatch: Dispatch, getState?: Function) =>
  updateConfigItem(radarApi.ConfigItemType.StreamRuns, streamRun)(dispatch, getState);

export const deleteStreamRunConfigItem = (streamRunId: string) => (dispatch: Dispatch, getState?: Function) =>
  deleteConfigItem(radarApi.ConfigItemType.StreamRuns, streamRunId)(dispatch, getState);

export const loadConfigItems =
  (
    configItemType: radarApi.ConfigItemType,
    refreshData: boolean = false,
    doNotUpdateConfigItems: boolean = false,
    callback: Function = null
  ) =>
  (dispatch: Dispatch, getState?: Function) => {
    appInsights.startTrackEvent("LoadConfigItems");
    dispatch(actionCreator.loadConfigItemsStarted());

    radarApi
      .fetchConfigItems(configItemType, refreshData, isStaging(getState, configItemType))
      .then((configItems: any) => {
        dispatch(actionCreator.loadConfigItemsCompleted(configItemType, configItems, doNotUpdateConfigItems));
        callback && callback(configItems);
        appInsights.stopTrackEvent("LoadConfigItems", { configItemType });
      })
      .catch((error) => dispatch(actionCreator.loadConfigItemsFailed(error)));
  };

export const loadConfigEnvironments =
  (callback: (environments: any) => void = null) =>
  (dispatch: Dispatch, getState?: Function) => {
    appInsights.startTrackEvent("LoadConfigEnvironments");
    dispatch(actionCreator.loadConfigEnvironmentsStarted());

    radarApi
      .fetchConfigEnvironments()
      .then((configEnvironments: any) => {
        dispatch(actionCreator.loadConfigEnvironmentsCompleted(configEnvironments));
        callback && callback(configEnvironments);
        appInsights.stopTrackEvent("LoadConfigEnvironments", { configEnvironments });
      })
      .catch((error) => dispatch(actionCreator.loadConfigEnvironmentsFailed(error)));
  };

export const loadConfigItem =
  (
    configItemType: radarApi.ConfigItemType,
    configItemId: string,
    environment: string = null,
    refreshData: boolean = false,
    doNotUpdateConfigValue: boolean = false,
    callback: (configItem) => void = null
  ) =>
  (dispatch: Dispatch, getState?: Function) => {
    appInsights.startTrackEvent("LoadConfigItem");
    dispatch(actionCreator.loadConfigItemStarted(configItemId));

    radarApi
      .fetchConfigItem(configItemType, configItemId, environment, refreshData, isStaging(getState, configItemType))
      .then((configItem: any) => {
        // Copy id field if the configItem is an empty object (i.e. no content is returned).
        if (!Object.keys(configItem)?.length) {
          configItem["id"] = configItemId;
        }

        dispatch(
          actionCreator.loadConfigItemCompleted(configItem, configItemType, doNotUpdateConfigValue, environment)
        );
        appInsights.stopTrackEvent("LoadConfigItem", { configItemType, configItemId, environment });
        callback && callback(configItem);
      })
      .catch((error) => dispatch(actionCreator.loadConfigItemFailed(error)));
  };

export const loadConfigItemByEnvironment =
  (
    configItemType: radarApi.ConfigItemType,
    configItemId: string,
    doNotUpdateConfigValue: boolean,
    environment: string = "",
    refreshData: boolean = false,
    callback: (configItem: any) => void = null,
    allowEnvironmentErrors: boolean = false
  ) =>
  (dispatch: Dispatch, getState?: Function) => {
    appInsights.startTrackEvent("LoadConfigItem" + environment ? "" : ` - ${environment}`);

    if (!environment) {
      dispatch(actionCreator.loadConfigItemStarted(configItemId));
    }
    radarApi
      .fetchConfigItem(configItemType, configItemId, environment, refreshData, isStaging(getState, configItemType))
      .then((configItem: any) => {
        dispatch(
          actionCreator.loadConfigItemCompleted(configItem, configItemType, doNotUpdateConfigValue, environment)
        );
        appInsights.stopTrackEvent("LoadConfigItem" + environment ? "" : ` - ${environment}`, {
          configItemType,
          configItemId,
        });
        callback && callback(configItem);
      })
      .catch((error) => {
        if (!environment || allowEnvironmentErrors) {
          dispatch(actionCreator.loadConfigItemFailed(error));
        }
      });
  };

export const loadConfigAttributeValuesFromDoc =
  (serviceConfig: any, document: any, version: string, uid: string) => (dispatch: Dispatch, getState?: Function) => {
    appInsights.startTrackEvent("loadConfigAttributeValuesFromDoc");
    dispatch(actionCreator.loadConfigAttributeValuesStarted(uid));

    radarApi
      .fetchConfigAttributeValues(serviceConfig, document, version)
      .then((response: any) => {
        return response.json();
      })
      .then((data: any) => {
        appInsights.stopTrackEvent("loadConfigAttributeValuesFromDoc", data);
        dispatch(actionCreator.loadConfigAttributeValuesCompleted(uid, data));
      })
      .catch((error) => {
        dispatch(actionCreator.loadConfigAttributeValuesFailed(error));
        console.error("fetchConfigValue failed: ", error);
      });
  };

export const updateConfigItem =
  (configItemType: radarApi.ConfigItemType, configItem, environment?: string, callback: (configItem) => void = null) =>
  (dispatch: Dispatch, getState?: Function) => {
    appInsights.startTrackEvent("UpdateConfigItem");
    dispatch(actionCreator.updateConfigItemStarted());

    radarApi
      .updateConfigItem(configItemType, configItem, isStaging(getState, configItemType), environment)
      .then((newConfigItem: any) => {
        dispatch(actionCreator.updateConfigItemCompleted(configItemType, newConfigItem, environment));
        loadConfigItems(configItemType, true)(dispatch, getState);

        if (configItem.id !== newConfigItem.id) {
          let lastSlashIndex = window.location.hash.lastIndexOf("/"),
            newHash = window.location.hash.substring(0, lastSlashIndex) + "/" + newConfigItem.id;

          window.location.href = window.location.pathname + newHash + window.location.search;
        }

        callback && callback(newConfigItem);

        appInsights.stopTrackEvent("UpdateConfigItem", {
          configItemType,
          configItemId: configItem.id,
          newConfigItemId: newConfigItem.id,
        });
      })
      .catch((error) => dispatch(actionCreator.updateConfigItemFailed(error)));
  };

export const deleteConfigItem =
  (configItemType: radarApi.ConfigItemType, configItemId: string) => (dispatch: Dispatch, getState?: Function) => {
    appInsights.startTrackEvent("DeleteConfigItem");
    dispatch(actionCreator.deleteConfigItemStarted());

    radarApi
      .deleteConfigItem(configItemType, configItemId, isStaging(getState, configItemType))
      .then(() => {
        loadConfigItems(configItemType, true)(dispatch, getState);
        dispatch(actionCreator.deleteConfigItemCompleted());
        appInsights.stopTrackEvent("DeleteConfigItem", { configItemType, configItemId });
      })
      .catch((error) => dispatch(actionCreator.deleteConfigItemFailed(error)));
  };

export const duplicateConfigItem =
  (configItemType: radarApi.ConfigItemType, configItemId: string) => (dispatch: Dispatch, getState?: Function) => {
    appInsights.startTrackEvent("DuplicateConfigItem");
    dispatch(actionCreator.duplicateConfigItemStarted());

    radarApi
      .duplicateConfigItem(configItemType, configItemId, isStaging(getState, configItemType))
      .then((newConfigItem: any) => {
        dispatch(actionCreator.duplicateConfigItemCompleted(newConfigItem));
        loadConfigItems(configItemType, true)(dispatch, getState);

        let lastSlashIndex = window.location.hash.lastIndexOf("/"),
          newHash = window.location.hash.substring(0, lastSlashIndex) + "/" + newConfigItem.id;

        window.location.href = window.location.pathname + newHash + window.location.search;

        appInsights.stopTrackEvent("DuplicateConfigItem", {
          configItemType,
          configItemId,
          newConfigItemId: newConfigItem.id,
        });
      })
      .catch((error) => dispatch(actionCreator.duplicateConfigItemFailed(error)));
  };

export const publishConfigItem =
  (configItemType: radarApi.ConfigItemType, configItemId: string, logData: object) =>
  (dispatch: Dispatch, getState?: Function) => {
    appInsights.startTrackEvent("PublishConfigItem");
    dispatch(actionCreator.publishConfigItemStarted(configItemId));
    radarApi
      .publishConfigItem(configItemType, configItemId, logData, isStaging(getState))
      .then((configItem) => {
        dispatch(actionCreator.publishConfigItemCompleted(configItemId, configItem));
        appInsights.stopTrackEvent("PublishConfigItem", { configItemType, configItemId });
      })
      .catch((error) => dispatch(actionCreator.publishConfigItemFailed(error)));
  };

export const unpublishConfigItem =
  (configItemType: radarApi.ConfigItemType, configItemId: string, logData: object) =>
  (dispatch: Dispatch, getState?: Function) => {
    appInsights.startTrackEvent("UnpublishConfigItem");
    dispatch(actionCreator.publishConfigItemStarted(configItemId));
    radarApi
      .unpublishConfigItem(configItemType, configItemId, logData, isStaging(getState))
      .then((configItem) => {
        dispatch(actionCreator.publishConfigItemCompleted(configItemId, configItem));
        appInsights.stopTrackEvent("UnpublishConfigItem", { configItemType, configItemId });
      })
      .catch((error) => dispatch(actionCreator.publishConfigItemFailed(error)));
  };

export const revertConfigItem =
  (configItemType: radarApi.ConfigItemType, configItemId: string) => (dispatch: Dispatch, getState?: Function) => {
    appInsights.startTrackEvent("RevertConfigItem");
    dispatch(actionCreator.revertConfigItemStarted(configItemId));
    radarApi
      .revertConfigItem(configItemType, configItemId, isStaging(getState))
      .then((configItem) => {
        dispatch(actionCreator.revertConfigItemCompleted(configItemId, configItem));
        appInsights.stopTrackEvent("RevertConfigItem", { configItemType, configItemId });
      })
      .catch((error) => dispatch(actionCreator.revertConfigItemFailed(error)));
  };

export const updateAndPublishConfigItem =
  (configItemType: radarApi.ConfigItemType, configItem, logData: object, environment?: string) =>
  (dispatch: Dispatch, getState?: Function) => {
    updateConfigItem(configItemType, configItem, environment, (newConfigItem) => {
      publishConfigItem(configItemType, newConfigItem.id, logData)(dispatch, getState);
      loadConfigItem(configItemType, newConfigItem.id, null, true);
    })(dispatch, getState);
  };

export const downloadReportDataToExcel =
  (reportId: string, refreshData: boolean = false) =>
  (dispatch: Dispatch, getState?: Function) => {
    appInsights.startTrackEvent("DownloadReportDataToExcel");
    dispatch(actionCreator.downloadReportDataToExcelStarted());
    radarApi
      .downloadReportDataToExcel(reportId, refreshData, isStaging(getState))
      .then(() => {
        dispatch(actionCreator.downloadReportDataToExcelCompleted());
        appInsights.stopTrackEvent("DownloadReportDataToExcel", { reportId });
      })
      .catch((error) => dispatch(actionCreator.downloadReportDataToExcelFailed(error)));
  };

export const loadControlViewFilters =
  (refreshData: boolean = false) =>
  (dispatch: Dispatch, getState?: Function) => {
    loadAppSettingConfigItem("ControlViewFilters")(dispatch, getState);
  };

export const loadIcmConnectors =
  (refreshData: boolean = false, doNotUpdateConfigValue: boolean = false) =>
  (dispatch: Dispatch, getState?: Function) => {
    loadAppSettingConfigItem("IcMConnectors", null, refreshData, doNotUpdateConfigValue)(dispatch, getState);
  };

export const loadControlMetadata =
  (refreshData: boolean = false) =>
  (dispatch: Dispatch, getState?: Function) => {
    loadAppSettingConfigItem("ControlMetadata", null, refreshData, true)(dispatch, getState);
  };

export const loadDevOpsOrgs =
  (refreshData: boolean = false, doNotUpdateConfigValue: boolean = false) =>
  (dispatch: Dispatch, getState?: Function) => {
    loadAppSettingConfigItem("DevOpsOrgs", null, refreshData, doNotUpdateConfigValue)(dispatch, getState);
  };

export const loadStandardTemplates =
  (refreshData: boolean = false, doNotUpdateConfigValue: boolean = true) =>
  (dispatch: Dispatch, getState?: Function) => {
    loadAppSettingConfigItem("StandardTemplates", null, refreshData, doNotUpdateConfigValue)(dispatch, getState);
  };

export const loadIncidentViewDefaultColumns =
  (refreshData: boolean = false, doNotUpdateConfigValue: boolean = true) =>
  (dispatch: Dispatch, getState?: Function) => {
    loadAppSettingConfigItem(
      "IncidentViewDefaultColumns",
      null,
      refreshData,
      doNotUpdateConfigValue
    )(dispatch, getState);
  };

export const loadEntity =
  (entityType: string, entityId: string, entityVersion: string = "", refreshData: boolean = false) =>
  (dispatch: Dispatch, getState?: Function) => {
    appInsights.startTrackEvent("LoadEntity");
    dispatch(actionCreator.loadEntityStarted(entityType, entityId, entityVersion));
    radarApi
      .loadEntity(entityType, entityId, entityVersion, refreshData, isStaging(getState))
      .then((entity) => {
        dispatch(actionCreator.loadEntityCompleted(entity, entityType));

        entity && loadEntityTests(entityType, entityId, entityVersion, refreshData)(dispatch, getState);

        appInsights.stopTrackEvent("LoadEntity", { entityType, entityId, entityVersion });
      })
      .catch((error) => dispatch(actionCreator.loadEntityFailed(error)));
  };

export const loadEntityRelationships =
  (entityType: string, entityId: string, entityVersion: string = "", refreshData: boolean = false) =>
  (dispatch: Dispatch, getState?: Function) => {
    appInsights.startTrackEvent("LoadEntityRelationships");
    dispatch(actionCreator.loadEntityRelationshipsStarted(entityType, entityId, entityVersion));
    radarApi
      .loadEntityRelationships(entityType, entityId, entityVersion, refreshData, isStaging(getState))
      .then((entity) => {
        dispatch(actionCreator.loadEntityRelationshipsCompleted(entity));

        appInsights.stopTrackEvent("LoadEntityRelationships", { entityType, entityId, entityVersion });
      })
      .catch((error) => dispatch(actionCreator.loadEntityRelationshipsFailed(error)));
  };

export const loadEntityDetails =
  (entityType: string, entityId: string, entityVersion: string, refreshData: boolean = false) =>
  (dispatch: Dispatch, getState?: Function) => {
    appInsights.startTrackEvent("LoadEntityDetails");
    dispatch(actionCreator.loadEntityDetailsStarted());

    radarApi
      .loadEntityDetails(entityType, entityId, entityVersion, refreshData, isStaging(getState))
      .then((entity) => {
        dispatch(actionCreator.loadEntityDetailsCompleted(entity));
        appInsights.stopTrackEvent("LoadEntityDetails", { entityType, entityId, entityVersion });
      })
      .catch((error) => dispatch(actionCreator.loadEntityDetailsFailed(error)));
  };

export const loadDevOpsView =
  (devOpsViewId: string, filters?: IFilterItem[], refreshData: boolean = false) =>
  (dispatch: Dispatch, getState?: Function) => {
    appInsights.startTrackEvent("LoadDevOpsView");
    dispatch(actionCreator.loadDevOpsViewStarted(devOpsViewId));
    radarApi
      .loadDevOpsView(devOpsViewId, filters, refreshData, isStaging(getState))
      .then((result) => {
        dispatch(actionCreator.loadDevOpsViewCompleted(result));
        appInsights.stopTrackEvent("LoadDevOpsView", { devOpsViewId });
      })
      .catch((error) => dispatch(actionCreator.loadDevOpsViewFailed(error)));
  };

export const loadDevOpsViews =
  (devOpsViewId?: string, refreshData?: boolean) => (dispatch: Dispatch, getState?: Function) => {
    appInsights.startTrackEvent("LoadDevOpsViews");
    dispatch(actionCreator.loadDevOpsViewsStarted());
    radarApi
      .fetchConfigItems(radarApi.ConfigItemType.DevOpsViews, refreshData, isStaging(getState))
      .then((devOpsViews: any) => {
        dispatch(actionCreator.loadDevOpsViewsCompleted(devOpsViews));

        if (devOpsViews && devOpsViews.length) {
          if (!devOpsViewId) {
            var nonFeatureRequestsViews = devOpsViews.filter((view) => view.id !== featureRequestsViewId);
            devOpsViewId = nonFeatureRequestsViews && nonFeatureRequestsViews.length && nonFeatureRequestsViews[0].id;
          }

          var devOpsView = devOpsViews.find((view) => view.id === devOpsViewId);

          if (devOpsView) {
            var filters = getDevOpsFilterItems(devOpsView.filters);

            loadDevOpsView(devOpsViewId, filters, refreshData)(dispatch, getState);
          }
        }

        appInsights.stopTrackEvent("LoadDevOpsViews", { devOpsViewId });
      })
      .catch((error) => dispatch(actionCreator.loadDevOpsViewsFailed(error)));
  };

export const generateDevOpsWorkItems =
  (checklistId: string, parameters?: object) => (dispatch: Dispatch, getState?: Function) => {
    appInsights.startTrackEvent("GenerateDevOpsWorkItems");
    dispatch(actionCreator.generateDevOpsWorkItemsStarted());
    radarApi
      .generateDevOpsWorkItems(checklistId, parameters, isStaging(getState))
      .then((result) => {
        dispatch(actionCreator.generateDevOpsWorkItemsCompleted(result));
        appInsights.stopTrackEvent("GenerateDevOpsWorkItems", { checklistId });
      })
      .catch((error) => dispatch(actionCreator.generateDevOpsWorkItemsFailed(error)));
  };

export const downloadDevOpsEmailFile = (devOpsId: string) => (dispatch: Dispatch, getState?: Function) => {
  appInsights.startTrackEvent("DownloadDevOpsEmailFile");
  dispatch(actionCreator.downloadEmailFileStarted());
  radarApi
    .downloadDevOpsEmailFile(devOpsId, isStaging(getState))
    .then(() => {
      dispatch(actionCreator.downloadEmailFileCompleted());
      appInsights.stopTrackEvent("DownloadDevOpsEmailFile", { devOpsId });
    })
    .catch((error) => dispatch(actionCreator.downloadEmailFileFailed(error)));
};

export const deleteDevOpsWorkItem =
  (devOpsViewId: string, devOpsId: string, includeDescendants: boolean = false) =>
  (dispatch: Dispatch, getState?: Function) => {
    appInsights.startTrackEvent("DeleteDevOpsWorkItem");
    dispatch(actionCreator.deleteDevOpsWorkItemStarted());
    radarApi
      .deleteDevOpsWorkItem(devOpsId, includeDescendants, isStaging(getState))
      .then(() => {
        loadDevOpsViews(devOpsViewId, true)(dispatch, getState);
        dispatch(actionCreator.deleteDevOpsWorkItemCompleted());
        appInsights.stopTrackEvent("DeleteDevOpsWorkItem", { devOpsId });
      })
      .catch((error) => dispatch(actionCreator.deleteDevOpsWorkItemFailed(error)));
  };

export const loadFinancialEntityJournalItems =
  (financialRecordType: string, numberToSelect: number, uid: string, ageInDays: number) =>
  (dispatch: Dispatch, getState?: Function) => {
    appInsights.startTrackEvent("loadFinancialEntityJournalItems");
    dispatch(actionCreator.loadFinancialEntityJournalItemsStarted(financialRecordType, numberToSelect));

    radarApi
      .fetchFinancialEntityJournalItems(financialRecordType, numberToSelect, ageInDays)
      .then((sampleRecords: any) => {
        dispatch(actionCreator.loadFinancialEntityJournalItemsCompleted(financialRecordType, sampleRecords, uid));
        appInsights.stopTrackEvent("loadFinancialEntityJournalItems", {
          financialRecordType,
          sampleRecords,
          uid,
          ageInDays: `${ageInDays}`,
        });
      })
      .catch((error) => dispatch(actionCreator.loadFinancialEntityJournalItemsFailed(error)));
  };

export const loadTeamTiles =
  (teamId: string = null, reportId: string = null, refreshData: boolean = false) =>
  (dispatch: Dispatch, getState?: Function) => {
    var state = getState().modules;
    teamId = teamId || state.selected_team_id || (state.teams?.length && state.teams[0].id);

    var selectedTeam = state.teams?.find((team) => team.id === teamId);
    reportId = reportId || selectedTeam?.reportId;

    if (!teamId || !reportId) {
      return;
    }

    appInsights.startTrackEvent("LoadTeamReport");
    dispatch(actionCreator.loadTeamReportStarted(teamId, reportId));

    radarApi
      .fetchReport(reportId, null, refreshData, false)
      .then((report: any) => {
        dispatch(actionCreator.loadTeamReportCompleted(teamId, report));
        appInsights.stopTrackEvent("LoadTeamReport");

        if (!report || report.type === ReportType.powerbi) {
          return;
        }

        appInsights.startTrackEvent("LoadTeamTiles");
        dispatch(actionCreator.loadTeamTilesStarted(teamId));

        radarApi
          .fetchTeamReport(teamId, refreshData)
          .then((tiles: any) => {
            dispatch(actionCreator.loadTeamTilesCompleted(tiles));
            appInsights.stopTrackEvent("LoadTeamTiles", { teamId, refreshData: refreshData.toString() });
          })
          .catch((error) => dispatch(actionCreator.loadTeamTilesFailed(error)));
      })
      .catch((error) => dispatch(actionCreator.loadTeamReportFailed(error)));
  };

export const loadTeamOkrMetrics =
  (teamId: string = null, refreshData: boolean = false) =>
  (dispatch: Dispatch, getState?: Function) => {
    var state = getState().modules;
    teamId = teamId || state.selected_team_id || (state.teams?.length && state.teams[0].id);

    if (!teamId) return;

    appInsights.startTrackEvent("LoadTeamOkrMetrics");
    dispatch(actionCreator.loadTeamOkrMetricsStarted(teamId));

    radarApi
      .fetchTeamOkrMetrics(teamId, refreshData)
      .then((okrMetrics: any) => {
        dispatch(actionCreator.loadTeamOkrMetricsCompleted(okrMetrics));
        appInsights.stopTrackEvent("LoadTeamOkrMetrics");
      })
      .catch((error) => dispatch(actionCreator.loadTeamOkrMetricsFailed(error)));
  };

export const loadSearchResult = () => (dispatch: Dispatch, getState?: Function) => {};

export const loadSearchSuggestions = () => (dispatch: Dispatch, getState?: Function) => {};

export const setEnvironments = (environments: IEnvironment[]) => (dispatch: Dispatch) => {
  dispatch(actionCreator.setEnvironments(environments));
};
