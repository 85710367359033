import React from "react";
import classNames from "./ControlSdkDesigner.module.scss";
import { getControlSdkActionFields } from "./MyControlEdit.helper";
import { IControlSdkDesignerProps } from "./ControlSdkDesigner";
import ConfigItemEditForm from "../common/ConfigItemEditForm";

export interface IControlSdkActionEditorProps extends IControlSdkDesignerProps {
  selectedActionIndex: number;
}

export const ControlSdkActionEditor = (props: IControlSdkActionEditorProps) => {
  const { control, controlSdk, selectedActionIndex, editConfigItem } = props;
  const selectedAction = control.controlSdkSettings?.actions && control.controlSdkSettings.actions[selectedActionIndex];

  const editActionConfig = (actionConfigValue: string) => {
    var actionConfigObject = JSON.parse(actionConfigValue);
    control.controlSdkSettings.actions[selectedActionIndex] = actionConfigObject;

    var controlConfigValue = JSON.stringify(control, null, 2);
    editConfigItem && editConfigItem(controlConfigValue);
  };

  return (
    <div className={classNames.panelContent}>
      <ConfigItemEditForm
        fields={getControlSdkActionFields(controlSdk, control?.controlSdkSettings, selectedActionIndex)}
        configItem={selectedAction || {}}
        editConfigItem={editActionConfig}
      />
    </div>
  );
};

export default ControlSdkActionEditor;
