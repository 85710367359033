import React from "react";
import { getControlSdkOutputFields } from "./MyControlEdit.helper";
import classNames from "./ControlSdkDesigner.module.scss";
import { IControlSdkDesignerProps } from "./ControlSdkDesigner";
import ConfigItemEditForm from "../common/ConfigItemEditForm";

export interface IControlSdkOutputEditorProps extends IControlSdkDesignerProps {
  selectedOutputIndex: number;
}

export const ControlSdkOutputEditor = (props: IControlSdkOutputEditorProps) => {
  const { control, controlSdk, selectedOutputIndex, editConfigItem } = props;
  const selectedOutput = control.controlSdkSettings?.Outputs && control.controlSdkSettings.Outputs[selectedOutputIndex];

  const editOutputConfig = (outputConfigValue: string) => {
    var outputConfigObject = JSON.parse(outputConfigValue);
    control.controlSdkSettings.Outputs[selectedOutputIndex] = outputConfigObject;

    var controlConfigValue = JSON.stringify(control, null, 2);
    editConfigItem && editConfigItem(controlConfigValue);
  };

  return (
    <div className={classNames.panelContent}>
      <ConfigItemEditForm
        fields={getControlSdkOutputFields(controlSdk, control?.controlSdkSettings, selectedOutputIndex)}
        configItem={selectedOutput || {}}
        editConfigItem={editOutputConfig}
      />
    </div>
  );
};

export default ControlSdkOutputEditor;
