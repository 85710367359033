import React, { createContext, useEffect, useState } from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router-dom";
import HealthDiagram from "./HealthDiagram";
import ControlList from "./ControlList";
import HealthReportHeader from "./HealthReportHeader";
import ControlViewFilters from "./ControlViewFilters";
import ControlDetails from "./ControlDetails";
import { IState } from "../../reducers/interfaces";
import { actionCreator } from "../duck";
import { appInsights } from "../../app/telemetryHelper";
import classNames from "./HealthReport.module.scss";
import { errorType } from "../interfaces";
import { IFilter } from "../common/interfaces";
import { INode } from "../../shared/components/Diagram";
import ControlSearchModal from "./ControlSearchModal";
import { IControlReport, IControlResult } from "./interfaces";

export interface MatchParams {
  viewId?: string;
  controlId?: string;
  nodeId?: string;
}

export interface IHealthReportStateProps {
  userLoggedIn: boolean;
  selectedControl: IControlResult;
  selectedControlIndex: number;
  selectedControlViewId: string;
  areControlViewsDefined: boolean;
  defaultControlViewId: string;
  areControlViewFiltersDefined: boolean;
  error: string;
  controlReport: IControlReport;
}

export interface IHealthReportDispatchProps {
  selectAllControls: () => void;
  loadControlViews: (
    controlViewIds: string,
    refreshData?: boolean,
    hasControls?: boolean,
    controlId?: string,
    postControlResultsCallback?: () => void
  ) => void;
  loadControlViewFilters: () => void;
  loadControl: (controlId: string, refreshData?: boolean) => void;
  selectSystemNode: (node: INode) => void;
}

export interface IHealthReportProps
  extends IHealthReportStateProps,
    IHealthReportDispatchProps,
    RouteComponentProps<MatchParams> {}

export interface IControlContext {
  viewId?: string;
  controlId?: string;
}

export const ControlContext = createContext<IControlContext>({});

export const HealthReport = (props: IHealthReportProps) => {
  const {
    areControlViewFiltersDefined,
    userLoggedIn,
    match,
    error,
    history,
    selectedControl,
    controlReport,
    loadControl,
    loadControlViews,
    loadControlViewFilters,
    selectSystemNode,
  } = props;
  const [viewId, setViewId] = useState<string>(match?.params?.viewId);
  const [controlId, setControlId] = useState<string>(match?.params?.controlId);
  const [nodeId] = useState<string>(match?.params?.nodeId);
  const [showControlSearch, setShowControlSearch] = useState<boolean>(false);

  useEffect(() => {
    controlId !== match?.params?.controlId && setControlId(match?.params?.controlId);
  }, [controlId, match?.params?.controlId]);

  useEffect(() => {
    viewId !== match?.params?.viewId && setViewId(match?.params?.viewId);
  }, [viewId, match?.params?.viewId]);

  useEffect(() => {
    if (controlId) {
      appInsights.trackPageView({ name: "ControlDetailPage", properties: { viewId, controlId } });
    } else {
      appInsights.trackPageView({ name: "ControlHomePage", properties: { viewId } });
    }
  }, [viewId, controlId]);

  useEffect(() => {
    if (!areControlViewFiltersDefined) {
      loadControlViewFilters();
    }
  }, [areControlViewFiltersDefined, loadControlViewFilters]);

  useEffect(() => {
    if (userLoggedIn) {
      if (controlId && !viewId) {
        loadControl(controlId);
      } else {
        loadControlViews(viewId, false, false, controlId, () => {
          // Select the diagram node on default of nodeId is defined from link
          nodeId && selectSystemNode({ id: nodeId });
        });
      }
    }
  }, [userLoggedIn, viewId, controlId, nodeId, loadControl, loadControlViews, selectSystemNode]);

  const onShowControlSearch = () => setShowControlSearch(true);

  return (
    <ControlContext.Provider value={{ viewId, controlId }}>
      <div className={classNames.appModuleContent}>
        <HealthReportHeader onShowControlSearch={onShowControlSearch} history={history} error={error} />
        <div className={classNames.appModuleInnerContent}>
          {error ? (
            <div className={classNames.error}>{error}</div>
          ) : controlId ? (
            <ControlDetails />
          ) : (
            <>
              <ControlViewFilters history={history} />
              <HealthDiagram />
              <ControlList history={history} />
            </>
          )}
          <ControlSearchModal
            controlId={controlId}
            controlTitle={selectedControl?.name}
            isOpen={showControlSearch}
            lineItemColumns={controlReport?.columns}
            onDismiss={() => setShowControlSearch(false)}
          />
        </div>
      </div>
    </ControlContext.Provider>
  );
};

const mapStateToProps = (state: IState): IHealthReportStateProps => {
  const controlViewFilters = state.modules.control_view_filters;
  return {
    userLoggedIn: !!state.app.login_user,
    selectedControl: state.modules.selected_control,
    selectedControlIndex: state.modules.selected_control_index,
    selectedControlViewId: state.modules.selected_control_view_id,
    areControlViewsDefined: Object.keys(state.modules.control_views).length > 0,
    defaultControlViewId: getSelectedControlViewFilterId(controlViewFilters),
    areControlViewFiltersDefined: controlViewFilters && controlViewFilters.length > 0,
    error:
      state.modules.errors[errorType.config] ||
      state.modules.errors[errorType.controls] ||
      state.modules.errors[errorType.controlResults] ||
      state.app.login_user_info?.error,
    controlReport: state.modules.control_report,
  };
};

const mapDispatchToProps = {
  selectAllControls: actionCreator.selectAllControls,
  loadControlViews: actionCreator.loadControlViews,
  loadControlViewFilters: actionCreator.loadControlViewFilters,
  loadControl: actionCreator.loadControl,
  selectSystemNode: actionCreator.selectSystemNode,
};

export default connect(mapStateToProps, mapDispatchToProps)(HealthReport);

const getSelectedControlViewFilterId = (controlViewFilters: IFilter[]): string => {
  var result = null;

  // Return the first selected control view.
  controlViewFilters?.forEach((filter) => {
    filter.items?.forEach((filterItem) => {
      if (!result && filterItem.selected) {
        result = filterItem.value;
      }
    });
  });

  // Default to return the first control view.
  return result || (controlViewFilters?.length && controlViewFilters[0].items[0].value);
};
