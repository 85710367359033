import React from "react";
import classNames from "./ControlRating.module.scss";
import { Rating } from "../../shared/components/common/Rating";
import { Stack } from "@fluentui/react";

export interface IControlRatingProps {
  controlId: string;
}

export const ControlRating = () => {
  return (
    <div className={classNames.ratingRoot}>
      <div className={classNames.category}>
        <div className={classNames.header}>
          <Rating rating={4} />
          <div className={classNames.text}>Accuracy</div>
        </div>
        <Stack horizontal>
          <div className={classNames.kpi}>KPI</div>
          <div className={classNames.kpi}>KPI</div>
          <div className={classNames.kpi}>KPI</div>
        </Stack>
      </div>
      <div className={classNames.category}>
        <div className={classNames.header}>
          <Rating rating={5} />
          <div className={classNames.text}>Timeliness</div>
        </div>
        <Stack horizontal>
          <div className={classNames.kpi}>KPI</div>
          <div className={classNames.kpi}>KPI</div>
          <div className={classNames.kpi}>KPI</div>
        </Stack>
      </div>
      <div className={classNames.category}>
        <div className={classNames.header}>
          <Rating rating={3} />
          <div className={classNames.text}>Reliability</div>
        </div>
        <Stack horizontal>
          <div className={classNames.kpi}>KPI</div>
          <div className={classNames.kpi}>KPI</div>
          <div className={classNames.kpi}>KPI</div>
        </Stack>
      </div>
    </div>
  );
};

export default ControlRating;
