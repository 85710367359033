import React from "react";
import { connect } from "react-redux";
import { actionCreator } from "../../duck";
import { leftNavUrls } from "../../../app/LeftNav.helper";
import { PivotItem } from "@fluentui/react/lib/Pivot";
import { defaultDevOpsView, getFields } from "./MyDevOpsViewEdit.helper";
import ConfigItemEditForm from "../common/ConfigItemEditForm";
import ConfigItemEdit, {
  IConfigItemEditProps,
  IConfigItemEditDispatchProps,
  mapStateToProps as mapStateToPropsBase,
  mapDispatchToProps as mapDispatchToPropsBase,
} from "../common/ConfigItemEdit";
import classNames from "../common/ConfigItemEdit.module.scss";
import { IState } from "../../../reducers/interfaces";
import { IDevOpsOrg } from "../../common/interfaces";
import { ConfigItemType } from "../../../api/radarApi";

export interface IMyDevOpsViewEditStateProps extends IConfigItemEditProps {
  devOpsOrgs: IDevOpsOrg[];
}

export interface IMyDevOpsViewEditDispatchProps extends IConfigItemEditDispatchProps {
  loadDevOpsOrgs: (refresh?: boolean, doNotUpdateConfigValue?: boolean) => void;
}

export class MyDevOpsViewEdit extends React.Component<IMyDevOpsViewEditStateProps & IMyDevOpsViewEditDispatchProps> {
  private generalEditForm: React.RefObject<any> = React.createRef();

  componentDidMount() {
    const { devOpsOrgs, loadDevOpsOrgs } = this.props;

    if (!devOpsOrgs || !devOpsOrgs.length) {
      loadDevOpsOrgs(true, true);
    }
  }

  render() {
    const { configValue, editConfigItem, devOpsOrgs } = this.props;
    let configItem = null;

    try {
      configItem = JSON.parse(configValue);
    } catch {}

    return (
      <ConfigItemEdit
        {...this.props}
        configItem={configItem}
        configItemName="DevOps View"
        configItemType={ConfigItemType.DevOpsViews}
        appInsightsPageName="MyDevOpsViewEditPage"
        leftNavUrl={leftNavUrls.management.devOpsView}
        defaultConfigItem={defaultDevOpsView}
        getFieldErrors={this.getFieldErrors}
        pivotItems={[
          <PivotItem key="general" itemKey="general" className={classNames.pivotItem} headerText="General">
            <ConfigItemEditForm
              configItem={configItem}
              fields={getFields(configItem, devOpsOrgs)}
              editConfigItem={editConfigItem}
              ref={this.generalEditForm}
            />
          </PivotItem>,
        ]}
      />
    );
  }

  getFieldErrors = () => {
    return this.generalEditForm && this.generalEditForm.current && this.generalEditForm.current.getErrors();
  };
}

const mapStateToProps = (state: IState) => ({
  ...mapStateToPropsBase(state),
  devOpsOrgs: state.modules.devops_orgs,
});

const mapDispatchToProps: IMyDevOpsViewEditDispatchProps = {
  ...mapDispatchToPropsBase,
  loadConfigItem: actionCreator.loadDevOpsViewConfigItem,
  updateConfigItem: actionCreator.updateDevOpsViewConfigItem,
  loadDevOpsOrgs: actionCreator.loadDevOpsOrgs,
};

export default connect(mapStateToProps, mapDispatchToProps)(MyDevOpsViewEdit);
