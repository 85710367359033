import {
  radarApiUrl,
  fetchDataWithAuth,
  getQueryString,
  getQueryStringWithIdType,
  ResponseDataType,
  isGlobalEnvironment,
} from "./apiBase";
import { IControlResultStatus, IControl } from "../appModules/controls/interfaces";
import {
  IComment,
  ILinkedItem,
  IDevOpsViewFilter,
  ISearchFilter,
  IDataSource,
  IMetricFilter,
} from "../appModules/common/interfaces";
import { IIncidentView } from "../appModules/incidents/interfaces";
import { IReportGroup } from "../appModules/common/interfaces";
import { IChatMessage } from "../shared/components/Chat/Chat.types";
import { controlSdk } from "./controlSdkTestData";

const scopes = ["e07325e1-b8dc-4b59-83c4-5530e8681b8e/user_impersonation"];

const incidentQueryBase = "api/incident/";
const userQueryBase = "api/user/";
const controlQueryBase = "api/control/";
const reportQueryBase = "api/report/";
const configQueryBase = "api/config/";
const graphQueryBase = "api/graph/";
const devopsQueryBase = "api/devops/";
const checklistQueryBase = "api/checklist/";
const teamQueryBase = "api/team/";
const dataProcessingBase = "api/dataprocessing/";

const incidentsQuery = `${incidentQueryBase}items/`;
const incidentViewsQuery = `${incidentQueryBase}views`;
const incidentDetailsQuery = `${incidentQueryBase}details/`;
const incidentTilesQuery = `${incidentQueryBase}tiles/`;
const incidentViewQuery = `${incidentQueryBase}view/`;
const incidentExcelQuery = `${incidentQueryBase}excel`;
const incidentQuery = `${incidentQueryBase}item`;
const incidentOpenAiSummaryQuery = `${incidentQueryBase}openaisummary/`;
const userRolesQuery = `${userQueryBase}roles`;
const userInfoQuery = `${userQueryBase}info`;
const userFeedbackQuery = `${userQueryBase}feedback`;
const userMemberGroupsQuery = `${userQueryBase}groups`;
const userSettingsQuery = `${userQueryBase}settings`;
const userTopPromptsQuery = `${userQueryBase}topprompts/`;
const isUserInGroupQuery = `${userQueryBase}is-in-group/`;
const antiforgeryTokenQuery = `${userQueryBase}antiforgery/token`;
const controlResultsQuery = `${controlQueryBase}results`;
const controlResultQuery = `${controlQueryBase}result/`;
const controlResultItemsQuery = `${controlQueryBase}result-items/`;
const controlResultDetailsQuery = `${controlQueryBase}result-details/`;
const controlResultHistoryQuery = `${controlQueryBase}result-history/`;
const controlAllResultsQuery = `${controlQueryBase}allresults/`;
const controlAllLineItemsQuery = `${controlQueryBase}alllineitems/`;
const controlViewQuery = `${controlQueryBase}views/`;
const controlsQuery = `${controlQueryBase}controls`;
const entitiesQuery = `${controlQueryBase}entities`;
const controlTilesQuery = `${controlQueryBase}tiles/`;
const controlCommentQuery = `${controlQueryBase}comment`;
const controlLinkedItemQuery = `${controlQueryBase}linked-item`;
const controlQuery = `${controlQueryBase}control/`;
const controlIncidentQuery = `${controlQueryBase}incident/`;
const controlErrorsQuery = `${controlQueryBase}errors/`;
const controlMeControlsQuery = `${controlQueryBase}mecontrols/`;
const controlRunQuery = `${controlQueryBase}run/`;
const controlSdkQuery = `${controlQueryBase}controlsdk/schema`;
const reportQuery = `${reportQueryBase}item/`;
const reportTileQuery = `${reportQueryBase}tile/`;
const reportTileColumnQuery = `${reportQueryBase}tile/column`;
const reportTileDataQuery = `${reportQueryBase}tile/submit`;
const reportGroupsQuery = `${reportQueryBase}groups`;
const reportGroupQuery = `${reportQueryBase}group/`;
const reportGroupPublishQuery = `${reportQueryBase}group/publish/`;
const reportExcelQuery = `${reportQueryBase}excel/`;
const graphEntityQuery = `${graphQueryBase}entity/`;
const graphRelationshipsQuery = `${graphQueryBase}relationships/`;
const graphEntityDetailsQuery = `${graphQueryBase}details/`;
const graphEntityTestsQuery = `${graphQueryBase}tests/`;
const graphEntitiesQuery = `${graphQueryBase}entities`;
const graphEntityMetrics = `${graphQueryBase}metrics`;
const graphEntityTraceMetrics = `${graphQueryBase}traces`;
const graphEntityTiles = `${graphQueryBase}tiles`;
const devopsViewQuery = `${devopsQueryBase}view/`;
const generateChecklistQuery = `${checklistQueryBase}generate-checklist/`;
const downloadDevOpsEmailFileQuery = `${checklistQueryBase}get-email-summary-file/`;
const deleteDevOpsItemQuery = `${devopsQueryBase}item/`;
const configExcelQuery = `${configQueryBase}excel/`;
const configDataSourceTestQuery = `${configQueryBase}datasourcetest/`;
const teamTilesQuery = `${teamQueryBase}tiles/`;
const teamReportQuery = `${teamQueryBase}report/`;
const teamOkrMetricsQuery = `${teamQueryBase}okrmetrics/`;
const teamHealthMetricsQuery = `${teamQueryBase}healthmetrics/`;
const teamTrendChartsQuery = `${teamQueryBase}trendcharts/`;
const teamHealthDownloadQuery = `${teamQueryBase}healthexcel/`;
const teamStreamSchedulesQuery = `${teamQueryBase}streamschedules/`;
const teamStreamRunsQuery = `${teamQueryBase}streamruns/`;
const teamChatResponseQuery = `${teamQueryBase}chatresponse`;
const teamCdtTestRunsQuery = `${teamQueryBase}cdttestruns`;
const teamCdtTestSummaryQuery = `${teamQueryBase}cdttestsummary`;
const reprocessFinancialEntityQuery = `${dataProcessingBase}reprocessfe/`;
const serviceBusMessageExplorer = `${dataProcessingBase}servicebus/`;

export enum ConfigItemType {
  AnomalyTests = "AnomalyTests", // New pattern is to define the actual Cosmos DB container name here.
  AppSettings = "AppSettings",
  ChecklistConfig = "ChecklistConfig",
  CollectorService = "CollectorService",
  ComplianceDocs = "ComplianceDocs",
  ConfigDefs = "ConfigDefs",
  Controls = "Controls",
  ControlViews = "ControlViews",
  DevOpsViews = "DevOpsViews",
  Entities = "Entities",
  EntityViews = "EntityViews",
  EntityTraceViews = "EntityTraceViews",
  Events = "Events",
  FinancialEntities = "FinancialEntities",
  IncidentViews = "IncidentViews",
  ReportGroups = "ReportGroups",
  ServiceContent = "ServiceContent",
  StreamRuns = "StreamRuns",
  StreamSchedules = "StreamSchedules",
  Subscriptions = "Subscriptions",
  Teams = "Teams",
}

export const LiveOnlyConfigItemTypes: string[] = [
  ConfigItemType.AppSettings,
  ConfigItemType.Events,
  ConfigItemType.Subscriptions,
  ConfigItemType.Teams,
];

export const fetchIncidents = (
  incidentViewId: string,
  refreshData: boolean = false,
  isStaging: boolean = false,
  endpoint: string = radarApiUrl + incidentsQuery + incidentViewId + getQueryString(refreshData, isStaging)
): Promise<Object> => fetchDataWithAuth(endpoint, scopes, "Failed to fetch incidents data.");

export const fetchIncident = (
  incidentId: string,
  refreshData: boolean = false,
  isStaging: boolean = false,
  endpoint: string = radarApiUrl + incidentQuery + "/" + incidentId + getQueryString(refreshData, isStaging)
): Promise<Object> => fetchDataWithAuth(endpoint, scopes, "Failed to fetch incident data.");

export const fetchIncidentDetails = (
  id: string,
  refreshData: boolean = false,
  isStaging: boolean = false,
  endpoint: string = radarApiUrl + incidentDetailsQuery + id + getQueryString(refreshData, isStaging)
): Promise<Object> => fetchDataWithAuth(endpoint, scopes, "Failed to fetch incident details data.");

export const fetchIncidentViews = (
  refreshData: boolean = false,
  isStaging: boolean = false,
  endpoint: string = radarApiUrl + incidentViewsQuery + getQueryString(refreshData, isStaging)
): Promise<Object> => fetchDataWithAuth(endpoint, scopes, "Failed to fetch incident views data.");

export const fetchIncidentView = (
  incidentViewId: string,
  refreshData: boolean = false,
  isStaging: boolean = false,
  endpoint: string = radarApiUrl + incidentViewQuery + incidentViewId + getQueryString(refreshData, isStaging)
): Promise<Object> => fetchDataWithAuth(endpoint, scopes, "Failed to fetch incident view data.");

export const fetchIncidentTiles = (
  id: string,
  refreshData: boolean = false,
  isStaging: boolean = false,
  endpoint: string = radarApiUrl + incidentTilesQuery + id + getQueryString(refreshData, isStaging)
): Promise<Object> => fetchDataWithAuth(endpoint, scopes, "Failed to fetch incident tiles data.");

export const fetchIncidentOpenAiSummary = (
  incidentId: string,
  refreshData: boolean = false,
  isStaging: boolean = false,
  endpoint: string = radarApiUrl + incidentOpenAiSummaryQuery + incidentId + getQueryString(refreshData, isStaging)
): Promise<Object> => fetchDataWithAuth(endpoint, scopes, "Failed to fetch incident OpenAI summary data.");

export const fetchIncidentsOpenAiSummary = (
  incidentIds: string,
  refreshData: boolean = false,
  isStaging: boolean = false,
  endpoint: string = radarApiUrl + incidentOpenAiSummaryQuery + getQueryString(refreshData, isStaging)
): Promise<Object> =>
  fetchDataWithAuth(endpoint, scopes, "Failed to fetch incident OpenAI summary data.", ResponseDataType.json, {
    method: "POST",
    body: `{ "incidentIds": "${incidentIds}" }`,
  });

export const updateIncidentView = (
  incidentView: IIncidentView,
  isStaging: boolean = false,
  endpoint: string = radarApiUrl + incidentViewQuery + incidentView.id + getQueryString(false, isStaging)
): Promise<Object> => {
  const error =
    incidentView.id === "new" ? "Failed to create new incident view." : "Failed to update incident view data.";

  return fetchDataWithAuth(endpoint, scopes, error, ResponseDataType.json, {
    method: "PUT",
    body: JSON.stringify(incidentView),
  });
};

export const deleteIncidentView = (
  incidentViewId: string,
  isStaging: boolean = false,
  endpoint: string = radarApiUrl + incidentViewQuery + incidentViewId + getQueryString(false, isStaging)
): Promise<Object> => {
  return fetchDataWithAuth(endpoint, scopes, "Failed to delete incident view.", ResponseDataType.json, {
    method: "DELETE",
  });
};

export const downloadIncidentsDataToExcel = (
  data: any[],
  isStaging: boolean = false,
  endpoint: string = radarApiUrl + incidentExcelQuery + getQueryString(false, isStaging)
): Promise<Object> =>
  fetchDataWithAuth(endpoint, scopes, "Failed to download incidents data to Excel.", ResponseDataType.file, {
    method: "POST",
    body: JSON.stringify(data),
  });

export const downloadDataToExcel = (
  data: any[],
  worksheetName: string,
  isStaging: boolean = false,
  endpoint: string = radarApiUrl + configExcelQuery + worksheetName + getQueryString(false, isStaging)
): Promise<Object> =>
  fetchDataWithAuth(endpoint, scopes, "Failed to download data to Excel.", ResponseDataType.file, {
    method: "POST",
    body: JSON.stringify(data),
  });

export const testDataSource = (
  dataSource: IDataSource,
  endpoint: string = radarApiUrl + configDataSourceTestQuery
): Promise<Object> =>
  fetchDataWithAuth(endpoint, scopes, "Failed to load data source test data.", ResponseDataType.json, {
    method: "POST",
    body: JSON.stringify(dataSource),
  });

export const createIncident = (
  incidentData: any,
  isStaging: boolean = false,
  endpoint: string = radarApiUrl + incidentQuery + getQueryString(false, isStaging)
): Promise<Object> =>
  fetchDataWithAuth(endpoint, scopes, "Failed to create new incident.", ResponseDataType.json, {
    method: "PUT",
    body: JSON.stringify(incidentData),
  });

export const fetchUserRoles = (endpoint: string = radarApiUrl + userRolesQuery): Promise<Object> =>
  fetchDataWithAuth(endpoint, scopes, "Failed to fetch user roles data.");

export const fetchUserInfo = (
  userId: string = "",
  endpoint: string = radarApiUrl + userInfoQuery + userId
): Promise<Object> => fetchDataWithAuth(endpoint, scopes, "Failed to fetch user info data.");

export const saveUserFeedback = (
  feedbackData: object,
  endpoint: string = radarApiUrl + userFeedbackQuery
): Promise<Object> =>
  fetchDataWithAuth(endpoint, scopes, "Failed to save user feedback data.", ResponseDataType.json, {
    method: "POST",
    body: JSON.stringify(feedbackData),
  });

export const fetchUserMemberGroups = (
  userId: string = "",
  endpoint: string = radarApiUrl + userMemberGroupsQuery + userId
): Promise<Object> => fetchDataWithAuth(endpoint, scopes, "Failed to fetch user member groups data.");

export const fetchUserSettings = (endpoint: string = radarApiUrl + userSettingsQuery): Promise<Object> =>
  fetchDataWithAuth(endpoint, scopes, "Failed to fetch user settings data.");

export const saveUserSettings = (
  userSettings: object,
  endpoint: string = radarApiUrl + userSettingsQuery
): Promise<Object> =>
  fetchDataWithAuth(endpoint, scopes, "Failed to save user settings data.", ResponseDataType.json, {
    method: "POST",
    body: JSON.stringify(userSettings),
  });

export const fetchTopPrompts = (
  appModuleName: string,
  endpoint: string = radarApiUrl + userTopPromptsQuery + appModuleName
): Promise<Object> => fetchDataWithAuth(endpoint, scopes, "Failed to fetch top prompts data.");

export const fetchAntiforgeryToken = (endpoint: string = radarApiUrl + antiforgeryTokenQuery): Promise<Object> =>
  fetchDataWithAuth(endpoint, scopes, "Failed to fetch antiforgery token.", ResponseDataType.none);

export const checkIsUserInGroup = (
  groupName: string,
  endpoint: string = radarApiUrl + isUserInGroupQuery + groupName
): Promise<Object> => fetchDataWithAuth(endpoint, scopes, "Failed to check if user is in specific group.");

export const fetchControlResults = (
  refreshData: boolean = false,
  isStaging: boolean = false,
  endpoint: string = radarApiUrl + controlResultsQuery + getQueryString(refreshData, isStaging)
): Promise<Object> => fetchDataWithAuth(endpoint, scopes, "Failed to fetch control results data.");

export const fetchControlResult = (
  controlId: string,
  refreshData: boolean = false,
  isStaging: boolean = false,
  endpoint: string = radarApiUrl + controlResultQuery + controlId + "/" + getQueryString(refreshData, isStaging)
): Promise<Object> => fetchDataWithAuth(endpoint, scopes, "Failed to fetch control result data.");

export const fetchControlResultItems = (
  id: string,
  controlResultId: string = null,
  allItems: boolean = false,
  refreshData: boolean = false,
  isStaging: boolean = false,
  endpoint: string = radarApiUrl +
    controlResultItemsQuery +
    id +
    "/" +
    controlResultId +
    "/" +
    allItems +
    getQueryString(refreshData, isStaging)
): Promise<Object> => fetchDataWithAuth(endpoint, scopes, "Failed to fetch control result line items data.");

export const fetchControlResultDetails = (
  controlId: string,
  controlResultId: string,
  refreshData: boolean = false,
  isStaging: boolean = false,
  endpoint: string = radarApiUrl +
    controlResultDetailsQuery +
    controlId +
    "/" +
    controlResultId +
    getQueryString(refreshData, isStaging)
): Promise<Object> => fetchDataWithAuth(endpoint, scopes, "Failed to fetch control result details data.");

export const updateControlResultDetails = (
  controlId: string,
  resultStatus: IControlResultStatus,
  isStaging: boolean = false,
  endpoint: string = radarApiUrl +
    controlResultDetailsQuery +
    controlId +
    "/" +
    resultStatus.controlResultId +
    getQueryString(false, isStaging)
): Promise<Object> => {
  return fetchDataWithAuth(endpoint, scopes, "Failed to update control result details data.", ResponseDataType.json, {
    method: "POST",
    body: JSON.stringify(resultStatus),
  });
};

export const updateControlComment = (
  comment: IComment,
  isStaging: boolean = false,
  endpoint: string = radarApiUrl + controlCommentQuery + getQueryString(false, isStaging)
): Promise<Object> => {
  return fetchDataWithAuth(endpoint, scopes, "Failed to update control comment data.", ResponseDataType.json, {
    method: "POST",
    body: JSON.stringify(comment),
  });
};

export const updateControlLinkedItem = (
  linkedItem: ILinkedItem,
  isStaging: boolean = false,
  endpoint: string = radarApiUrl + controlLinkedItemQuery + getQueryString(false, isStaging)
): Promise<Object> => {
  return fetchDataWithAuth(endpoint, scopes, "Failed to update control linked item data.", ResponseDataType.json, {
    method: "POST",
    body: JSON.stringify(linkedItem),
  });
};

export const updateControl = (
  control: IControl,
  isStaging: boolean = false,
  endpoint: string = radarApiUrl + controlQuery + control.id + getQueryString(false, isStaging)
): Promise<Object> => {
  const error = control.id === "new" ? "Failed to create new control." : "Failed to update control data.";

  return fetchDataWithAuth(endpoint, scopes, error, ResponseDataType.json, {
    method: "PUT",
    body: JSON.stringify(control),
  });
};

export const deleteControl = (
  controlId: string,
  isStaging: boolean = false,
  endpoint: string = radarApiUrl + controlQuery + controlId + getQueryString(false, isStaging)
): Promise<Object> => {
  return fetchDataWithAuth(endpoint, scopes, "Failed to delete control.", ResponseDataType.json, {
    method: "DELETE",
  });
};

export const createControlIncident = (
  controlId: string,
  controlResultId: string,
  isStaging: boolean = false,
  endpoint: string = radarApiUrl +
    controlIncidentQuery +
    controlId +
    "/" +
    controlResultId +
    getQueryString(false, isStaging)
): Promise<Object> => {
  return fetchDataWithAuth(endpoint, scopes, "Failed to create control incident.", ResponseDataType.json, {
    method: "PUT",
  });
};

export const fetchControlResultHistory = (
  controlId: string,
  timeAgo: string = "7d",
  refreshData: boolean = false,
  isStaging: boolean = false,
  endpoint: string = radarApiUrl +
    controlResultHistoryQuery +
    controlId +
    "/" +
    timeAgo +
    getQueryString(refreshData, isStaging)
): Promise<Object> => fetchDataWithAuth(endpoint, scopes, "Failed to fetch control result history data.");

export const fetchControlViews = (
  ids: string,
  refreshData: boolean = false,
  isStaging: boolean = false,
  endpoint: string = radarApiUrl + controlViewQuery + ids + getQueryString(refreshData, isStaging)
): Promise<Object> => fetchDataWithAuth(endpoint, scopes, "Failed to fetch control view data.");

export const fetchControls = (
  refreshData: boolean = false,
  isStaging: boolean = false,
  endpoint: string = radarApiUrl + controlsQuery + getQueryString(refreshData, isStaging)
): Promise<Object> => fetchDataWithAuth(endpoint, scopes, "Failed to fetch controls data.");

export const fetchControl = (
  controlId: string,
  refreshData: boolean = false,
  isStaging: boolean = false,
  endpoint: string = radarApiUrl + controlQuery + controlId + getQueryString(refreshData, isStaging)
): Promise<Object> => fetchDataWithAuth(endpoint, scopes, "Failed to fetch control data.");

export const fetchEntities = (
  refreshData: boolean = false,
  isStaging: boolean = false,
  endpoint: string = radarApiUrl + entitiesQuery + getQueryString(refreshData, isStaging)
): Promise<Object> =>
  fetchDataWithAuth(endpoint, scopes, "Failed to fetch entities data.", ResponseDataType.json, {
    method: "POST",
    body: "{}",
  });

export const fetchControlTiles = (
  id: string,
  selectedValue: string = "",
  refreshData: boolean = false,
  isStaging: boolean = false,
  endpoint: string = radarApiUrl + controlTilesQuery + id + "/" + selectedValue + getQueryString(refreshData, isStaging)
): Promise<Object> => fetchDataWithAuth(endpoint, scopes, "Failed to fetch control tiles data.");

export const fetchControlErrors = (
  controlId: string,
  refreshData: boolean = false,
  endpoint: string = radarApiUrl + controlErrorsQuery + controlId + getQueryString(refreshData, false)
): Promise<Object> => fetchDataWithAuth(endpoint, scopes, "Failed to fetch control errors data.");

export const fetchMeControls = (
  controlViewId: string,
  refreshData: boolean = false,
  endpoint: string = radarApiUrl + controlMeControlsQuery + controlViewId + getQueryString(refreshData, false)
): Promise<Object> => fetchDataWithAuth(endpoint, scopes, "Failed to fetch ME controls data.");

export const startControlRun = (
  controlId: string,
  endpoint: string = radarApiUrl + controlRunQuery + controlId
): Promise<Object> => fetchDataWithAuth(endpoint, scopes, "Failed to start control run.", ResponseDataType.text);

export const fetchControlAllResults = (
  controlId: string,
  filters: object,
  refreshData: boolean = false,
  isStaging: boolean = false,
  endpoint: string = radarApiUrl + controlAllResultsQuery + controlId + getQueryString(refreshData, isStaging)
): Promise<Object> =>
  fetchDataWithAuth(endpoint, scopes, "Failed to fetch control all results data.", ResponseDataType.json, {
    method: "POST",
    body: JSON.stringify(filters),
  });

export const fetchControlAllLineItems = (
  controlId: string,
  filters: object,
  refreshData: boolean = false,
  isStaging: boolean = false,
  endpoint: string = radarApiUrl + controlAllLineItemsQuery + controlId + getQueryString(refreshData, isStaging)
): Promise<Object> =>
  fetchDataWithAuth(endpoint, scopes, "Failed to fetch control all line items data.", ResponseDataType.json, {
    method: "POST",
    body: JSON.stringify(filters),
  });

export const fetchReport = (
  reportId: string,
  filters: object = null,
  refreshData: boolean = false,
  isStaging: boolean = false,
  endpoint: string = radarApiUrl + reportQuery + reportId + getQueryString(refreshData, isStaging)
): Promise<Object> =>
  fetchDataWithAuth(endpoint, scopes, "Failed to fetch report data.", ResponseDataType.json, {
    method: "POST",
    body: filters ? JSON.stringify(filters) : "[]",
  });

export const fetchReportTile = (
  reportId: string,
  tileIndex: number,
  value: string,
  refreshData: boolean = false,
  isStaging: boolean = false,
  endpoint: string = radarApiUrl +
    reportTileQuery +
    reportId +
    "/" +
    tileIndex +
    "/" +
    value +
    getQueryString(refreshData, isStaging)
): Promise<Object> => fetchDataWithAuth(endpoint, scopes, "Failed to fetch report tile data.");

export const fetchReportTileColumnData = (
  reportId: string,
  tileIndex: number,
  columnName: string,
  columnValue: string,
  filters: object = null,
  refreshData: boolean = false,
  isStaging: boolean = false,
  endpoint: string = radarApiUrl + reportTileColumnQuery + getQueryString(refreshData, isStaging)
): Promise<Object> =>
  fetchDataWithAuth(endpoint, scopes, "Failed to fetch report tile column data.", ResponseDataType.json, {
    method: "POST",
    body: JSON.stringify({
      reportId,
      tileIndex,
      columnName,
      columnValue,
      filters,
    }),
  });

export const submitReportTileData = (
  reportId: string,
  tileIndex: number,
  fieldName: string,
  fieldValue: string,
  filters: object = null,
  refreshData: boolean = false,
  isStaging: boolean = false,
  endpoint: string = radarApiUrl + reportTileDataQuery + getQueryString(refreshData, isStaging)
): Promise<Object> =>
  fetchDataWithAuth(endpoint, scopes, "Failed to submit report tile data.", ResponseDataType.json, {
    method: "POST",
    body: JSON.stringify({
      reportId,
      tileIndex,
      fieldName,
      fieldValue,
      filters,
    }),
  });

export const fetchReportGroup = (
  reportGroupId: string,
  refreshData: boolean = false,
  isStaging: boolean = false,
  endpoint: string = radarApiUrl + reportGroupQuery + reportGroupId + getQueryString(refreshData, isStaging)
): Promise<Object> => fetchDataWithAuth(endpoint, scopes, "Failed to fetch report group data.");

export const fetchReportGroups = (
  refreshData: boolean = false,
  isStaging: boolean = false,
  endpoint: string = radarApiUrl + reportGroupsQuery + getQueryString(refreshData, isStaging)
): Promise<Object> => fetchDataWithAuth(endpoint, scopes, "Failed to fetch report groups.");

export const updateReportGroup = (
  reportGroup: IReportGroup,
  isStaging: boolean = false,
  endpoint: string = radarApiUrl + reportGroupQuery + reportGroup.id + getQueryString(false, isStaging)
): Promise<Object> => {
  const error = reportGroup.id === "new" ? "Failed to create new report group." : "Failed to update report group data.";

  return fetchDataWithAuth(endpoint, scopes, error, ResponseDataType.json, {
    method: "PUT",
    body: JSON.stringify(reportGroup),
  });
};

export const deleteReportGroup = (
  reportGroupId: string,
  isStaging: boolean = false,
  endpoint: string = radarApiUrl + reportGroupQuery + reportGroupId + getQueryString(false, isStaging)
): Promise<Object> => {
  return fetchDataWithAuth(endpoint, scopes, "Failed to delete report group.", ResponseDataType.json, {
    method: "DELETE",
  });
};

export const publishReportGroup = (
  reportGroupId: string,
  logData: object,
  isStaging: boolean = false,
  endpoint: string = radarApiUrl + reportGroupPublishQuery + reportGroupId + getQueryString(false, isStaging)
): Promise<Object> => {
  return fetchDataWithAuth(endpoint, scopes, "Failed to publish report group.", ResponseDataType.json, {
    method: "PUT",
    body: JSON.stringify(logData),
  });
};

export const downloadReportDataToExcel = (
  reportId: string,
  refreshData: boolean = false,
  isStaging: boolean = false,
  endpoint: string = radarApiUrl + reportExcelQuery + reportId + getQueryString(refreshData, isStaging)
): Promise<Object> =>
  fetchDataWithAuth(endpoint, scopes, "Failed to download report data to Excel.", ResponseDataType.file);

export const fetchConfigItems = (
  configItemType: ConfigItemType,
  refreshData: boolean = false,
  isStaging: boolean = false,
  endpoint: string = radarApiUrl + configQueryBase + configItemType + getQueryString(refreshData, isStaging)
): Promise<Object> => fetchDataWithAuth(endpoint, scopes, `Failed to fetch ${configItemType} config items.`);

export const fetchConfigEnvironments = (
  endpoint: string = `${radarApiUrl}${configQueryBase}Environments`
): Promise<Object> => fetchDataWithAuth(endpoint, scopes, `Failed to fetch config environments.`);

export const fetchConfigItem = (
  configItemType: ConfigItemType,
  configItemId: string,
  environment: string = null,
  refreshData: boolean = false,
  isStaging: boolean = false,
  endpoint: string = radarApiUrl +
    configQueryBase +
    configItemType +
    "/" +
    (environment ? `${environment}/` : "") +
    configItemId +
    getQueryString(refreshData, isStaging)
): Promise<Object> =>
  fetchDataWithAuth(
    endpoint,
    scopes,
    `Failed to fetch ${configItemType} config item.`,
    ResponseDataType.json,
    undefined,
    { doNotErrorOnNoContent: !isGlobalEnvironment(environment) }
  );

export const fetchConfigItemHistory = (
  configItemType: ConfigItemType,
  configItemId: string,
  environment: string,
  refreshData: boolean = false,
  isStaging: boolean = false,
  idType: string = "",
  endpoint: string = radarApiUrl +
    configQueryBase +
    "history/" +
    configItemType +
    "/" +
    environment +
    "/" +
    configItemId +
    getQueryStringWithIdType(refreshData, isStaging, idType)
): Promise<Object> =>
  fetchDataWithAuth(endpoint, scopes, `Failed to fetch ${configItemType} history for ${configItemId}.`);

export const fetchConfigHistoryItem = (
  configItemType: ConfigItemType,
  configItemId: string,
  configDate: string,
  environment: string,
  isChangeHistory: boolean = true,
  refreshData: boolean = false,
  isStaging: boolean = false,
  idType: string = "",
  endpoint: string = radarApiUrl +
    configQueryBase +
    "historyitem/" +
    configItemType +
    "/" +
    environment +
    "/" +
    configItemId +
    "/" +
    configDate +
    "/" +
    isChangeHistory +
    getQueryStringWithIdType(refreshData, isStaging, idType)
): Promise<Object> =>
  fetchDataWithAuth(
    endpoint,
    scopes,
    `Failed to fetch ${configItemType} history item for ${configItemId}, date=${configDate}, isChangeHistory=${isChangeHistory}.`
  );

export const fetchFinancialEntityJournalItems = (
  financialRecordType: string,
  numberToSelect: number,
  ageInDays: number = 1,
  endpoint: string = `${radarApiUrl}api/financialentity/${financialRecordType}?numberToSelect=${numberToSelect}&days=${ageInDays}`
): Promise<Object> => fetchDataWithAuth(endpoint, scopes, `Failed to fetch ${financialRecordType} sample records.`);

export const fetchConfigAttributeValues = (
  serviceConfig: any,
  document: any,
  schemaVersion: string,
  endpoint: string = radarApiUrl + configQueryBase + "ServiceContent/attributes"
): Promise<Object> =>
  fetchDataWithAuth(endpoint, scopes, `Failed to fetch attribute values from supplied config and document.`, null, {
    method: "post",
    body: JSON.stringify({ serviceConfig, document, schemaVersion, refresh: true }),
  });

export const updateConfigItem = (
  configItemType: ConfigItemType,
  configItem,
  isStaging: boolean = false,
  environment: string = "",
  endpoint: string = radarApiUrl +
    configQueryBase +
    configItemType +
    "/" +
    (environment ? `${environment}/` : "") +
    (configItem?.id ? configItem.id : "new") +
    getQueryString(false, isStaging)
): Promise<Object> => {
  return fetchDataWithAuth(endpoint, scopes, `Failed to update ${configItemType} data.`, ResponseDataType.json, {
    method: "PUT",
    body: JSON.stringify(configItem),
  });
};

export const deleteConfigItem = (
  configItemType: ConfigItemType,
  configItemId: string,
  isStaging: boolean = false,
  endpoint: string = radarApiUrl +
    configQueryBase +
    configItemType +
    "/" +
    configItemId +
    getQueryString(false, isStaging)
): Promise<Object> => {
  return fetchDataWithAuth(endpoint, scopes, `Failed to delete ${configItemType} data.`, ResponseDataType.json, {
    method: "DELETE",
  });
};

export const duplicateConfigItem = (
  configItemType: ConfigItemType,
  configItemId: string,
  isStaging: boolean = false,
  endpoint: string = radarApiUrl +
    configQueryBase +
    configItemType +
    "/duplicate/" +
    configItemId +
    getQueryString(false, isStaging)
): Promise<Object> => {
  return fetchDataWithAuth(
    endpoint,
    scopes,
    `Failed to duplicate ${configItemType} with id ${configItemId}.`,
    ResponseDataType.json,
    {
      method: "PUT",
    }
  );
};

export const publishConfigItem = (
  configItemType: ConfigItemType,
  configItemId: string,
  logData: object,
  isStaging: boolean = false,
  endpoint: string = radarApiUrl +
    configQueryBase +
    configItemType +
    "/publish/" +
    configItemId +
    getQueryString(false, isStaging)
): Promise<Object> => {
  return fetchDataWithAuth(endpoint, scopes, `Failed to publish ${configItemType}.`, ResponseDataType.json, {
    method: "PUT",
    body: JSON.stringify(logData),
  });
};

export const unpublishConfigItem = (
  configItemType: ConfigItemType,
  configItemId: string,
  logData: object,
  isStaging: boolean = false,
  endpoint: string = radarApiUrl +
    configQueryBase +
    configItemType +
    "/unpublish/" +
    configItemId +
    getQueryString(false, isStaging)
): Promise<Object> => {
  return fetchDataWithAuth(endpoint, scopes, `Failed to unpublish ${configItemType}.`, ResponseDataType.json, {
    method: "PUT",
    body: JSON.stringify(logData),
  });
};

export const revertConfigItem = (
  configItemType: ConfigItemType,
  configItemId: string,
  isStaging: boolean = false,
  endpoint: string = radarApiUrl +
    configQueryBase +
    configItemType +
    "/revert/" +
    configItemId +
    getQueryString(false, isStaging)
): Promise<Object> => {
  return fetchDataWithAuth(endpoint, scopes, `Failed to revert ${configItemType}.`, ResponseDataType.json, {
    method: "PUT",
  });
};

export const deployConfigItem = (
  configItemType: ConfigItemType,
  configItemId: string,
  environment: string,
  endpoint: string = radarApiUrl +
    configQueryBase +
    "deploy/" +
    configItemType +
    "/" +
    environment +
    "/" +
    configItemId +
    getQueryString(false, true)
): Promise<Object> => {
  return fetchDataWithAuth(
    endpoint,
    scopes,
    `Failed to deploy ${configItemType} with id ${configItemId} to ${environment}.`
  );
};

export const queryReprocessFinancialEntities = (kustoQuery: string): Promise<Object> => {
  var endpoint = radarApiUrl + reprocessFinancialEntityQuery + "query";
  return fetchDataWithAuth(endpoint, scopes, `Failed to execute query: ${kustoQuery}.`, ResponseDataType.json, {
    method: "POST",
    body: JSON.stringify(kustoQuery),
  });
};

export const reprocessFinancialEntities = (
  financialEntitiesToReprocess: Object[],
  reprocessFlags: object
): Promise<Object> => {
  var endpoint = radarApiUrl + reprocessFinancialEntityQuery + "exec";
  return fetchDataWithAuth(endpoint, scopes, `Failed to reprocess financial entities.`, ResponseDataType.json, {
    method: "POST",
    body: JSON.stringify({ financialEntitiesToReprocess, reprocessFlags }),
  });
};

export const getServiceBusMessageSummary = (svbconfig: Object): Promise<Object> => {
  var endpoint = radarApiUrl + serviceBusMessageExplorer + "summary";
  return fetchDataWithAuth(endpoint, scopes, `Failed to access service bus: ${svbconfig}.`, ResponseDataType.json, {
    method: "POST",
    body: JSON.stringify(svbconfig),
  });
};

export const peekServiceBusMessage = (svbtopic: Object): Promise<Object> => {
  var endpoint = radarApiUrl + serviceBusMessageExplorer + "peek";
  return fetchDataWithAuth(endpoint, scopes, `Failed to peek service bus message.`, ResponseDataType.json, {
    method: "POST",
    body: JSON.stringify(svbtopic),
  });
};

export const getServiceBusTopic = (svbendpoint: string): Promise<Object> => {
  var endpoint = radarApiUrl + serviceBusMessageExplorer + "topic/" + svbendpoint;
  return fetchDataWithAuth(endpoint, scopes, `Failed to get service bus topic.`, ResponseDataType.json);
};

export const getServiceBusSubscriptionByTopic = (svbendpoint: string, topic: string): Promise<Object> => {
  var endpoint = radarApiUrl + serviceBusMessageExplorer + "subscription";
  return fetchDataWithAuth(endpoint, scopes, `Failed to get service bus topic subscription.`, ResponseDataType.json, {
    method: "POST",
    body: JSON.stringify({ svbendpoint, topic }),
  });
};

export const loadEntityData = (
  entityType: string,
  entityId: string,
  entityVersion: string = "",
  refreshData: boolean = false,
  isStaging: boolean = false,
  queryPath: string = "",
  dataType: string = ""
): Promise<Object> => {
  var endpoint = radarApiUrl + queryPath + getQueryString(refreshData, isStaging);
  return fetchDataWithAuth(
    endpoint,
    scopes,
    `Failed to load entity ${dataType} for entity type: ${entityType}, id: ${entityId}, version: ${entityVersion}.`,
    ResponseDataType.json,
    {
      method: "POST",
      body: JSON.stringify({
        entityType,
        entityId,
        entityVersion,
      }),
    }
  );
};

export const loadEntity = (
  entityType: string,
  entityId: string,
  entityVersion: string = "",
  refreshData: boolean = false,
  isStaging: boolean = false
): Promise<Object> => loadEntityData(entityType, entityId, entityVersion, refreshData, isStaging, graphEntityQuery);

export const loadEntityRelationships = (
  entityType: string,
  entityId: string,
  entityVersion: string = "",
  refreshData: boolean = false,
  isStaging: boolean = false
): Promise<Object> =>
  loadEntityData(entityType, entityId, entityVersion, refreshData, isStaging, graphRelationshipsQuery, "relations");

export const loadEntityTests = (
  entityType: string,
  entityId: string,
  entityVersion: string,
  refreshData: boolean = false,
  isStaging: boolean = false
): Promise<Object> =>
  loadEntityData(entityType, entityId, entityVersion, refreshData, isStaging, graphEntityTestsQuery, "tests");

export const loadEntityTraceMetrics = (
  entityType: string,
  entityId: string,
  entityVersion: string,
  refreshData: boolean = false,
  isStaging: boolean = false
): Promise<Object> =>
  loadEntityData(entityType, entityId, entityVersion, refreshData, isStaging, graphEntityTraceMetrics, "traces");

export const loadEntityDetails = (
  entityType: string,
  entityId: string,
  entityVersion: string,
  refreshData: boolean = false,
  isStaging: boolean = false
): Promise<Object> =>
  loadEntityData(entityType, entityId, entityVersion, refreshData, isStaging, graphEntityDetailsQuery, "details");

export const loadEntityMetrics = (
  anchorEntityTypes: string[],
  startTime: string,
  endTime: string,
  searchFilters: ISearchFilter[],
  maxVersionOnly: boolean = false,
  anchorDateAttribute: string = null,
  indexedAttributes: string[] = null,
  statsId: string = null,
  entityViewLevel: number = 4,
  entityTypes: string[] = null,
  refreshData: boolean = false,
  isStaging: boolean = false,
  endpoint: string = radarApiUrl + graphEntityMetrics + getQueryString(refreshData, isStaging)
): Promise<Object> => {
  let body = {
    anchorEntityTypes,
    startTime,
    endTime,
    searchFilters,
    maxVersionOnly,
    anchorDateAttribute,
    indexedAttributes,
    statsId,
    entityViewLevel,
    entityTypes,
  };

  return fetchDataWithAuth(endpoint, scopes, `Failed to load entity metrics.`, ResponseDataType.json, {
    method: "POST",
    body: JSON.stringify(body),
  });
};

export const loadEntityTiles = (
  anchorEntityTypes: string[],
  entityTypes: string[],
  startTime: string,
  endTime: string,
  searchFilters: ISearchFilter[],
  maxVersionOnly: boolean = false,
  anchorDateAttribute: string = null,
  indexedAttributes: string[] = null,
  statsId: string = null,
  refreshData: boolean = false,
  isStaging: boolean = false,
  endpoint: string = radarApiUrl + graphEntityTiles + getQueryString(refreshData, isStaging)
): Promise<Object> => {
  let body = {
    anchorEntityTypes,
    entityTypes,
    startTime,
    endTime,
    searchFilters,
    maxVersionOnly,
    anchorDateAttribute,
    indexedAttributes,
    statsId,
  };

  return fetchDataWithAuth(endpoint, scopes, `Failed to load entity tiles.`, ResponseDataType.json, {
    method: "POST",
    body: JSON.stringify(body),
  });
};

export const loadFinancialEntities = (
  entityTypes: string[],
  startTime: string,
  endTime: string,
  batchSize: number = null,
  entityType: string = null,
  metricId: string = null,
  searchFilters: ISearchFilter[],
  maxVersionOnly: boolean = false,
  anchorDateAttribute: string = null,
  displayColumns: string[] = null,
  indexedAttributes: string[] = null,
  randomTop: boolean = null,
  statsId: string = null,
  refreshData: boolean = false,
  isStaging: boolean = false,
  endpoint: string = radarApiUrl + graphEntitiesQuery + getQueryString(refreshData, isStaging)
): Promise<Object> => {
  let body = {
    entityTypes,
    startTime,
    endTime,
    entityType,
    metricId,
    searchFilters,
    maxVersionOnly,
    anchorDateAttribute,
    displayColumns,
    indexedAttributes,
    statsId,
  };

  batchSize !== null && (body["batchSize"] = batchSize);
  randomTop !== null && (body["randomTop"] = randomTop);

  return fetchDataWithAuth(endpoint, scopes, `Failed to load entities.`, ResponseDataType.json, {
    method: "POST",
    body: JSON.stringify(body),
  });
};

export const loadDevOpsView = (
  devOpsViewId: string,
  filters?: IDevOpsViewFilter[],
  refreshData: boolean = false,
  isStaging: boolean = false,
  endpoint: string = radarApiUrl + devopsViewQuery + devOpsViewId + getQueryString(refreshData, isStaging)
): Promise<Object> =>
  fetchDataWithAuth(endpoint, scopes, `Failed to load DevOps view with id ${devOpsViewId}.`, ResponseDataType.json, {
    method: "POST",
    body: filters ? JSON.stringify(filters) : "[]",
  });

export const generateDevOpsWorkItems = (
  checklistId: string,
  parameters: object,
  isStaging: boolean = false,
  endpoint: string = radarApiUrl + generateChecklistQuery + checklistId + getQueryString(false, isStaging)
): Promise<Object> =>
  fetchDataWithAuth(
    endpoint,
    scopes,
    `Failed to generate DevOps work items with checklist id ${checklistId}.`,
    ResponseDataType.json,
    {
      method: "POST",
      body: parameters ? JSON.stringify(parameters) : null,
    }
  );

export const downloadDevOpsEmailFile = (
  devOpsViewId: string,
  isStaging: boolean = false,
  endpoint: string = radarApiUrl + downloadDevOpsEmailFileQuery + devOpsViewId + getQueryString(false, isStaging)
): Promise<Object> =>
  fetchDataWithAuth(
    endpoint,
    scopes,
    `Failed to download email file with DevOps id ${devOpsViewId}.`,
    ResponseDataType.file
  );

export const deleteDevOpsWorkItem = (
  devOpsId: string,
  includeDescendants: boolean = false,
  isStaging: boolean = false,
  endpoint: string = radarApiUrl +
    deleteDevOpsItemQuery +
    devOpsId +
    "/" +
    includeDescendants +
    getQueryString(false, isStaging)
): Promise<Object> =>
  fetchDataWithAuth(endpoint, scopes, `Failed to delete DevOps item with id ${devOpsId}.`, ResponseDataType.json, {
    method: "DELETE",
  });

export const fetchTeamTiles = (
  teamId: string,
  refreshData: boolean = false,
  endpoint: string = radarApiUrl + teamTilesQuery + teamId + getQueryString(refreshData, false)
): Promise<Object> => fetchDataWithAuth(endpoint, scopes, "Failed to load team tiles data.");

export const fetchTeamReport = (
  teamId: string,
  refreshData: boolean = false,
  endpoint: string = radarApiUrl + teamReportQuery + teamId + getQueryString(refreshData, false)
): Promise<Object> => fetchDataWithAuth(endpoint, scopes, "Failed to load team report data.");

export const fetchTeamOkrMetrics = (
  teamId: string,
  refreshData: boolean = false,
  endpoint: string = radarApiUrl + teamOkrMetricsQuery + teamId + getQueryString(refreshData, false)
): Promise<Object> => fetchDataWithAuth(endpoint, scopes, "Failed to load team OKR metrics data.");

export const fetchTeamHealthMetrics = (
  filters: IMetricFilter[] = null,
  refreshData: boolean = false,
  endpoint: string = radarApiUrl + teamHealthMetricsQuery + getQueryString(refreshData, false)
): Promise<Object> =>
  fetchDataWithAuth(endpoint, scopes, "Failed to load team health metrics data.", ResponseDataType.json, {
    method: "POST",
    body: JSON.stringify({
      filters,
    }),
  });

export const fetchTeamTrendCharts = (
  teamId: string,
  metricGroupId: number,
  refreshData: boolean = false,
  endpoint: string = radarApiUrl +
    teamTrendChartsQuery +
    teamId +
    "/" +
    metricGroupId +
    getQueryString(refreshData, false)
): Promise<Object> => fetchDataWithAuth(endpoint, scopes, "Failed to load team charts data.");

export const downloadHealthDataToExcel = (
  data: object,
  endpoint: string = radarApiUrl + teamHealthDownloadQuery
): Promise<Object> =>
  fetchDataWithAuth(endpoint, scopes, "Failed to download health data to Excel.", ResponseDataType.file, {
    method: "POST",
    body: JSON.stringify(data),
  });

export const fetchStreamSchedules = (endpoint: string = radarApiUrl + teamStreamSchedulesQuery): Promise<Object> =>
  fetchDataWithAuth(endpoint, scopes, "Failed to fetch stream schedules data.");

export const fetchStreamRuns = (endpoint: string = radarApiUrl + teamStreamRunsQuery): Promise<Object> =>
  fetchDataWithAuth(endpoint, scopes, "Failed to fetch stream runs data.");

export const fetchCdtTestRuns = (endpoint: string = radarApiUrl + teamCdtTestRunsQuery): Promise<Object> =>
  fetchDataWithAuth(endpoint, scopes, "Failed to fetch CDT Test Runs data.");

export const fetchCdtTestSummary = (endpoint: string = radarApiUrl + teamCdtTestSummaryQuery): Promise<Object> =>
  fetchDataWithAuth(endpoint, scopes, "Failed to fetch CDT Test Summary data.");

export const fetchChatResponse = (
  messages: IChatMessage[],
  endpoint: string = radarApiUrl + teamChatResponseQuery
): Promise<Object> =>
  fetchDataWithAuth(endpoint, scopes, "Failed to chat response data.", ResponseDataType.json, {
    method: "POST",
    body: JSON.stringify({ messages }),
  });

export const fetchControlSdk = (endpoint: string = radarApiUrl + controlSdkQuery): Promise<Object> =>
  fetchDataWithAuth(endpoint, scopes, "Failed to fetch control SDK schema data.");

export const fetchControlSdk2 = (): Promise<Object> => new Promise((resolve) => resolve(controlSdk));
