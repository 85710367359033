import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { actionCreator } from "../../duck";
import { leftNavUrls } from "../../../app/LeftNav.helper";
import { PivotItem } from "@fluentui/react/lib/Pivot";
import { defaultAppSetting, getFields } from "./MyAppSettingEdit.helper";
import ConfigItemEditForm from "../common/ConfigItemEditForm";
import ConfigItemEdit, {
  IConfigItemEditProps,
  IConfigItemEditDispatchProps,
  mapStateToProps,
  mapDispatchToProps as mapDispatchToPropsBase,
} from "../common/ConfigItemEdit";
import classNames from "../common/ConfigItemEdit.module.scss";
import { ConfigItemType, fetchConfigItems, fetchConfigItem } from "../../../api/radarApi";
import { ITeam } from "../../common/interfaces";
import { IHealthMetricSet } from "../../home/HealthDashboard.types";

export const MyAppSettingEdit = (props: IConfigItemEditProps) => {
  const generalEditForm: React.RefObject<any> = React.createRef();
  const { configValue, editConfigItem } = props;
  const [teams, setTeams] = useState<ITeam[]>();
  const [healthMetricSets, setHealthMetricSets] = useState<IHealthMetricSet[]>([]);
  let configItem = null;

  try {
    configItem = JSON.parse(configValue);
  } catch {}

  useEffect(() => {
    fetchConfigItems(ConfigItemType.Teams).then((teams: any) => {
      setTeams(teams);
    });

    fetchConfigItem(ConfigItemType.AppSettings, "HealthMetricSets").then((configItem: any) =>
      setHealthMetricSets(configItem?.settings)
    );
  }, []);

  const getFieldErrors = () => {
    var generalEditFormErrors = generalEditForm?.current?.getErrors();
    return { ...generalEditFormErrors };
  };

  return (
    <ConfigItemEdit
      {...props}
      configItem={configItem}
      configItemName="App Setting"
      configItemType={ConfigItemType.AppSettings}
      appInsightsPageName="MyAppSettingEditPage"
      leftNavUrl={leftNavUrls.management.appSetting}
      defaultConfigItem={defaultAppSetting}
      getFieldErrors={getFieldErrors}
      pivotItems={[
        <PivotItem key="general" itemKey="general" className={classNames.pivotItem} headerText="General">
          <ConfigItemEditForm
            configItem={configItem}
            fields={getFields(configItem, teams, healthMetricSets)}
            editConfigItem={editConfigItem}
            ref={generalEditForm}
          />
        </PivotItem>,
      ]}
    />
  );
};

const mapDispatchToProps: IConfigItemEditDispatchProps = {
  ...mapDispatchToPropsBase,
  loadConfigItem: actionCreator.loadAppSettingConfigItem,
  updateConfigItem: actionCreator.updateAppSettingConfigItem,
};

export default connect(mapStateToProps, mapDispatchToProps)(MyAppSettingEdit);
