import React, { useContext } from "react";
import { connect } from "react-redux";
import { IState } from "../../reducers/interfaces";
import { IControlReport } from "./interfaces";
import { errorType } from "../interfaces";
import ControlItemList from "./ControlItemList";
import classNames from "./ControlItemList.module.scss";
import { Spinner } from "@fluentui/react/lib/Spinner";
import { actionCreator } from "../duck";
import { ControlContext } from "./HealthReport";

export interface ILineItemListStateProps {
  loadAllItems: boolean;
  controlType: string;
  lineItemTableTitle: string;
  controlReport: IControlReport;
  loadingLineItems: boolean;
  loadingControl: boolean;
  error: string;
  isStaging: boolean;
}

export interface ILineItemListDispatchProps {
  downloadDataToExcel: (data: any[], worksheetName: string) => void;
}

export const LineItemList = (props: ILineItemListStateProps & ILineItemListDispatchProps) => {
  const { controlId } = useContext(ControlContext);
  const {
    loadAllItems,
    controlType,
    lineItemTableTitle,
    loadingLineItems,
    loadingControl,
    error,
    controlReport,
    isStaging,
    downloadDataToExcel,
  } = props;

  if (!loadingControl) {
    if (!controlId) {
      if (isStaging) return null;
      else
        return (
          <div className={classNames.error}>
            The selected control is not found. Switch to staging mode to see if this control is only defined in staging
            mode at the moment.
          </div>
        );
    }
  } else {
    return (
      <Spinner
        styles={{
          root: classNames.spinner,
          circle: classNames.spinnerCircle,
          label: classNames.spinnerLabel,
        }}
        label="Loading control data..."
      />
    );
  }

  if (!controlReport) return null;

  const { items, columns, totalCount } = controlReport;

  const showAllItems = loadAllItems !== undefined ? loadAllItems : controlType === "Outlier",
    displayItems = showAllItems ? items : items && items.filter((item) => item.result !== "pass"),
    displayControlReport = { items: displayItems, columns, totalCount };

  const statusTitleText = showAllItems ? "" : "Failed ",
    title = lineItemTableTitle || `${statusTitleText}Control Line Items`,
    noItemText = `No ${statusTitleText.toLowerCase()}control item is found.`;

  return (
    <ControlItemList
      controlReport={displayControlReport}
      title={title}
      loading={loadingLineItems}
      error={error}
      noItemText={noItemText}
      downloadDataToExcel={downloadDataToExcel}
    />
  );
};

const mapStateToProps = (state: IState): ILineItemListStateProps => {
  const selectedControl = state.modules.selected_control;
  return {
    loadAllItems: selectedControl && selectedControl.loadAllItems,
    controlType: selectedControl && selectedControl.type,
    lineItemTableTitle: selectedControl && selectedControl.lineItemTableTitle,
    controlReport: state.modules.control_report,
    loadingLineItems: state.modules.loading_control_result_items,
    loadingControl:
      state.modules.loading_controls || state.modules.loading_control_view || state.modules.loading_control_results,
    error: state.modules.errors[errorType.controlResults],
    isStaging: state.app.is_staging,
  };
};

const mapDispatchToProps: ILineItemListDispatchProps = {
  downloadDataToExcel: actionCreator.downloadDataToExcel,
};

export default connect(mapStateToProps, mapDispatchToProps)(LineItemList);
