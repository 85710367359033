import React from "react";
import { Rating as FluentRating, IRatingProps as IFluentRatingProps } from "@fluentui/react/lib/Rating";
import { TooltipHost } from "@fluentui/react";

export interface IRatingProps extends IFluentRatingProps {
  tooltipContent?: string | JSX.Element | JSX.Element[];
}

export const Rating = (props: IRatingProps) => {
  const { tooltipContent, rating } = props;

  const finalTooltipContent = tooltipContent || `Rating: ${rating}`;

  return (
    <TooltipHost content={finalTooltipContent}>
      <FluentRating
        {...props}
        readOnly
        styles={{
          rootIsSmall: { height: 16 },
          ratingStarFront: { color: "#0078d4" },
          ratingButton: { padding: "0px 2px" },
        }}
      />
    </TooltipHost>
  );
};
