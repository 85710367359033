import React from "react";
import { getControlSdkInputFields } from "./MyControlEdit.helper";
import classNames from "./ControlSdkDesigner.module.scss";
import ConfigItemEditForm from "../common/ConfigItemEditForm";
import { IControlSdkDesignerProps } from "./ControlSdkDesigner";

export const ControlSdkInputEditor = (props: IControlSdkDesignerProps) => {
  const { control, controlSdk, editConfigItem } = props;

  const editInputConfig = (inputConfigValue: string) => {
    var inputConfigObject = JSON.parse(inputConfigValue);
    control.controlSdkSettings = { ...control.controlSdkSettings, ...inputConfigObject };

    var controlConfigValue = JSON.stringify(control, null, 2);
    editConfigItem && editConfigItem(controlConfigValue);
  };

  return (
    <div className={classNames.panelContent}>
      <ConfigItemEditForm
        fields={getControlSdkInputFields(control, controlSdk)}
        configItem={control?.controlSdkSettings || {}}
        editConfigItem={editInputConfig}
      />
    </div>
  );
};

export default ControlSdkInputEditor;
