import React from "react";
import { connect } from "react-redux";
import { actionCreator } from "../../duck";
import { leftNavUrls } from "../../../app/LeftNav.helper";
import { PivotItem } from "@fluentui/react/lib/Pivot";
import { defaultAnomalyTest, getFields } from "./MyAnomalyTestEdit.helper";
import ConfigItemEditForm from "../common/ConfigItemEditForm";
import ConfigItemEdit, {
  IConfigItemEditProps,
  IConfigItemEditDispatchProps,
  mapStateToProps,
  mapDispatchToProps as mapDispatchToPropsBase,
} from "../common/ConfigItemEdit";
import classNames from "../common/ConfigItemEdit.module.scss";
import { ConfigItemType } from "../../../api/radarApi";

export class MyAnomalyTestEdit extends React.Component<IConfigItemEditProps> {
  private generalEditForm: React.RefObject<any> = React.createRef();

  render() {
    const { configValue, editConfigItem } = this.props;
    let configItem = null;

    try {
      configItem = JSON.parse(configValue);
    } catch {}

    return (
      <ConfigItemEdit
        {...this.props}
        configItem={configItem}
        configItemName="Anomaly Test"
        configItemType={ConfigItemType.AnomalyTests}
        appInsightsPageName="MyAnomalyTestEditPage"
        leftNavUrl={leftNavUrls.management.anomalyTest}
        defaultConfigItem={defaultAnomalyTest}
        getFieldErrors={this.getFieldErrors}
        pivotItems={[
          <PivotItem key="general" itemKey="general" className={classNames.pivotItem} headerText="General">
            <ConfigItemEditForm
              configItem={configItem}
              fields={getFields(configItem)}
              editConfigItem={editConfigItem}
              ref={this.generalEditForm}
            />
          </PivotItem>,
        ]}
      />
    );
  }

  getFieldErrors = () => {
    var generalEditFormErrors =
      this.generalEditForm && this.generalEditForm.current && this.generalEditForm.current.getErrors();

    return { ...generalEditFormErrors };
  };
}

const mapDispatchToProps: IConfigItemEditDispatchProps = {
  ...mapDispatchToPropsBase,
  loadConfigItem: actionCreator.loadAnomalyTestConfigItem,
  updateConfigItem: actionCreator.updateAnomalyTestConfigItem,
};

export default connect(mapStateToProps, mapDispatchToProps)(MyAnomalyTestEdit);
