import React from "react";
import { connect } from "react-redux";
import { actionCreator } from "../../duck";
import { leftNavUrls } from "../../../app/LeftNav.helper";
import { PivotItem } from "@fluentui/react/lib/Pivot";
import { defaultCollectorService, getCollectorServiceFields } from "./MyCollectorServiceEdit.helper";
import ConfigItemEditForm from "../common/ConfigItemEditForm";
import ConfigItemEdit, {
  IConfigItemEditProps,
  IConfigItemEditDispatchProps,
  mapStateToProps as mapStateToPropsBase,
  mapDispatchToProps as mapDispatchToPropsBase,
} from "../common/ConfigItemEdit";
import classNames from "../common/ConfigItemEdit.module.scss";
import { IState } from "../../../reducers/interfaces";
import { ICollectorService } from "../../common/interfaces";
import { ConfigItemType } from "../../../api/radarApi";

export interface IMyCollectorServiceEditProps extends IConfigItemEditProps {
  collectorServices: ICollectorService[];
}

export interface IMyCollectorServiceEditDispatchProps extends IConfigItemEditDispatchProps {
  loadCollectorServices: (refresh?: boolean) => void;
}

export class MyCollectorServiceEdit extends React.Component<
  IMyCollectorServiceEditProps & IMyCollectorServiceEditDispatchProps
> {
  private generalEditForm: React.RefObject<any> = React.createRef();
  private validationEditForm: React.RefObject<any> = React.createRef();

  componentDidMount() {
    const { collectorServices, loadCollectorServices } = this.props;

    if (!collectorServices || !collectorServices.length) {
      loadCollectorServices(true);
    }
  }

  render() {
    const { configValue, editConfigItem } = this.props;

    let configItem = null;

    try {
      configItem = JSON.parse(configValue);
    } catch {}

    return (
      <ConfigItemEdit
        {...this.props}
        configItem={configItem}
        configItemName="Collector Service"
        configItemType={ConfigItemType.CollectorService}
        appInsightsPageName="MyCollectorServiceEditPage"
        leftNavUrl={leftNavUrls.management.collectorService}
        defaultConfigItem={defaultCollectorService}
        getFieldErrors={this.getFieldErrors}
        pivotItems={[
          <PivotItem
            key="general"
            itemKey="general"
            className={classNames.pivotItem}
            headerText="General"
            alwaysRender={true}
          >
            <ConfigItemEditForm
              configItem={configItem}
              fields={getCollectorServiceFields(configItem)}
              editConfigItem={editConfigItem}
              ref={this.generalEditForm}
            />
          </PivotItem>,
        ]}
      />
    );
  }

  getFieldErrors = () => {
    var generalEditFormErrors =
        this.generalEditForm && this.generalEditForm.current && this.generalEditForm.current.getErrors(),
      validationEditFormErrors =
        this.validationEditForm && this.validationEditForm.current && this.validationEditForm.current.getErrors();

    return { ...generalEditFormErrors, ...validationEditFormErrors };
  };
}

const mapStateToProps = (state: IState) => ({
  ...mapStateToPropsBase(state),
  collectorServices: state.modules.config_items,
});

const mapDispatchToProps: IMyCollectorServiceEditDispatchProps = {
  ...mapDispatchToPropsBase,
  loadConfigItem: actionCreator.loadCollectorServiceConfigItem,
  updateConfigItem: actionCreator.updateCollectorServiceConfigItem,
  loadCollectorServices: actionCreator.loadCollectorServiceConfigItems,
};

export default connect(mapStateToProps, mapDispatchToProps)(MyCollectorServiceEdit);
